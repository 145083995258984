import { ContentHeader } from "../../components/Content/ContentHeader";
import now from "../../assets/now.jpg";
import nob from "../../assets/nob.jpg";
import iw from "../../assets/iw.jpg";
import ib from "../../assets/ib.jpg";
import ow from "../../assets/ow.jpg";
import ob from "../../assets/ob.jpg";
import slih from "../../assets/slih.jpg";
import sliv from "../../assets/sliv.jpg";
import lrb from "../../assets/lrb1.jpg";
import lrw from "../../assets/lrw.jpg";
import nb from "../../assets/nb.jpg";
import nw from "../../assets/nw.jpg";
import bohflam from "../../assets/bohflam.jpg";
import armex from "../../assets/armex.jpg";
import noImage from "../../assets/no_image.png";
import heating from "../../assets/heating.jpg";
import eMagnetic from "../../assets/e-magnetic.jpg";
import priva from "../../assets/priva.jpg";
import spraytight from "../../assets/spraytight.jpg";
import weathertight from "../../assets/weathertight.jpg";
import watertight from "../../assets/watertight.jpg";
import yacht from "../../assets/yacht.jpg";
import lightweight from "../../assets/lightweight.jpg";
import fireRated from "../../assets/fire_rated.jpg";
import nonTypical from "../../assets/non_typical.jpg";

export const NonOpeningNOWContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Non opening – type NOW",
          h2Content: [
            "Non opening windows are designed mainly for mounting (welding or bolting) in walls of superstructure. Special shaped windows could also be installed in wheelhouse.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available.",
          ],
        }}
        image={now}
      />
    </>
  );
};

export const NonOpeningNOBContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Non opening – type NOB",
          h2Content: [
            "Non opening windows are designed mainly for mounting (welding or bolting) in walls of superstructure. Special shaped windows could also be installed in wheelhouse.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available.",
          ],
        }}
        image={nob}
      />
    </>
  );
};

export const InwardOpeningIWContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Inward opening – type IW",
          h2Content: [
            "Opening windows are designed mainly for mounting (welding or bolting) in walls of superstructure.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available.",
          ],
        }}
        image={iw}
      />
    </>
  );
};

export const InwardOpeningIBContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Inward opening – type IB",
          h2Content: [
            "Opening windows are designed mainly for mounting (welding or bolting) in walls of superstructure.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available.",
          ],
        }}
        image={ib}
      />
    </>
  );
};

export const OutwardOpeningOWContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Outward opening – type OW",
          h2Content: [
            "Opening windows are designed mainly for mounting (welding or bolting) in walls of superstructure.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available.",
          ],
        }}
        image={ow}
      />
    </>
  );
};

export const OutwardOpeningOBContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Outward opening – type OB",
          h2Content: [
            "Opening windows are designed mainly for mounting (welding or bolting) in walls of superstructure.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available.",
          ],
        }}
        image={ob}
      />
    </>
  );
};

export const SlidingSlihSlivContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Sliding windows – type SLIH/SLIV",
          h2Content: [
            "Sliding windows are designed mainly for mounting (welding or bolting) in walls of superstructure and wheelhouse.",
          ],
          h3Content: [
            "Windows are designed according to ISO 3903. Special shaped windows (trapezoid, parallelogram, etc.) are available. IMPORTANT. Sides of windows in way of sliding to be parallel.",
          ],
        }}
        image={slih}
      />
      <ContentHeader image={sliv} />
    </>
  );
};

export const OpeningLRBContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Opening – type L/RB",
          h2Content: [
            "Non opening side scuttles are designed mainly for mounting (welding or bolting) in hull.",
          ],
          h3Content: ["Side scuttles are designed according to ISO 1751."],
        }}
        image={lrb}
      />
    </>
  );
};

export const OpeningLRWContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Opening – type L/RW",
          h2Content: [
            "Non opening side scuttles are designed mainly for mounting (welding or bolting) in hull.",
          ],
          h3Content: ["Side scuttles are designed according to ISO 1751."],
        }}
        image={lrw}
      />
    </>
  );
};

export const NonOpeningNBContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Non opening – type NB",
          h2Content: [
            "Non opening side scuttles are designed mainly for mounting (welding or bolting) in hull.",
          ],
          h3Content: ["Side scuttles are designed according to ISO 1751."],
        }}
        image={nb}
      />
    </>
  );
};

export const NonOpeningNWContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Non opening – type NW",
          h2Content: [
            "Non opening side scuttles are designed mainly for mounting (welding or bolting) in hull.",
          ],
          h3Content: ["Side scuttles are designed according to ISO 1751."],
        }}
        image={nw}
      />
    </>
  );
};

export const BohflamContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "BOHFLAM",
          h2Content: [
            "Szyby ognioodporne BOHFLAM zapewniają ochronę podczas pożaru.",
          ],
          h3Content: [
            "Oferujemy różne konstrukcje i klasy odporności szyb. Dla statków są to najczęściej klasy B15, A0, A30, A60, H60, H120. ",
          ],
        }}
        image={bohflam}
      />
      <p>
        Szyby spełniają wymagania normy ISO 5797. Szyby ognioodporne{" "}
        <strong>BOHFLAM</strong> mogą być łączone z innymi rodzajami szyb, np. z
        szybami laminowanymi czy elektrogrzewczymi. Dla budownictwa najczęściej
        stosowane są szyby w klasach EI 30, EI 60, EI 90 oraz EI 120.
      </p>
    </>
  );
};

export const ArmexContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "ARMEX GLASS",
          h2Content: [
            "Bullet-resistant glass ensures suitable resistance to penetration from missiles of handgun, such as small arms or hunting weapons. ",
          ],
          h3Content: [""],
        }}
        image={armex}
      />
      <p>
        They have a wide range of applications and can be mounted in bank
        facilities, currency exchange bureaus, pawnshops, jeweller’s shops, as
        well as used in military, by private users or in different types of
        vehicles exposed to assaults – in general, in all areas of higher risk
        of gun assaults.
      </p>
      <p>
        Apart from bullet-resistant properties, this type of glass is a product
        characterized with increased break-in resistance properties, resistant
        to hits with such tools as a big hammer or axe. Bullet-resistant glass
        can be mounted alone or in combination with thermo-insulating,
        reflecting or fire-resistant glass.
      </p>
      <h2>BASIC TYPES OF GLASS</h2>
      <p>
        <strong>Splinter glass (BR-S).</strong> Glass should provide its user
        with body protection against injuries caused by bullets or their
        fragments. Glass splinters can form on the protecting side of the glass.
      </p>
      <p>
        <strong>Non-splinter glass (BR-NS).</strong> Glass should provide its
        user with body protection against injuries caused by bullets, their
        fragments or glass chips. Glass splinters cannot form on the side
        opposite to the side of the glass which is shot at.
      </p>
      <h2>TESTS AND CERTIFICATES</h2>
      <p>
        The products have been tested and have certificates of the Institute of
        Precision Mechanics in Warsaw (Accreditation Certification No. 041)
        confirming high standard of the product.
      </p>
      <h2>BULLET-RESISTANT GLASS CLASSES</h2>
      <p>
        Possible risks and the type of the protected facility should be
        determined prior to the selection of suitable glass class.
      </p>
      <p>
        <strong>
          List of possible applications of particular bullet-proof resistance
          classes:
        </strong>
      </p>
      <ul className="check-list">
        <li>BR1 – state administration facilities, manor houses</li>
        <li>BR2 – telephone and computer exchanges, car windows</li>
        <li>BR3 – buildings with higher robbery risk, cash booths</li>
        <li>BR4 – military equipment, prisons</li>
        <li>BR5 – military and other equipment with significant risk</li>
        <li>BR6 – facilities of particular significance</li>
        <li>BR7 – facilities of particular significance</li>
      </ul>
    </>
  );
};

export const SoundControlContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "SOUND CONTROL",
          h2Content: [
            "Ochrona przed hałasem ma znaczący wpływ na nasze zdrowie i poziom życia. Szyby dźwiękochłonne chronią przed różnymi zewnętrznymi źródłami hałasu jak ruch drogowy, kolejowy, lotniczy.",
          ],
        }}
        image={noImage}
      />
      <p>
        Szyby dźwiękochłonne mogą być zastosowane we wszystkich rodzajach
        stolarki i elewacji. Zastosowanie konkretnego typu szyby jest
        uzależnione od charakteru hałasu, który ma być wytłumiony (innego
        rodzaju szyby muszą być zastosowane do wytłumienia hałasu ruchu
        ulicznego, innego to wytłumienia hałasów emitowanych przez zakłady
        przemysłowe).
      </p>
      <p>Oferujemy szyby dźwiękochłonne:</p>
      <ul className="check-list">
        <li>dźwiękochłonne szyby zespolone,</li>
        <li>laminowane szkło dźwiękochłonne.</li>
      </ul>
      <p>
        Podstawowy sposób uzyskania wymaganych parametrów dźwiękochłonności
        polega na zastosowaniu szyb o zróżnicowanych grubościach (asymetryczna
        budowa szkła). Stopień dźwiękochłonności określa się jako tłumienie
        opisywane symbolem Rw, a podaje się jego wartość w decybelach. W ten
        sposób możemy uzyskać pakiety o budowie:
      </p>
      <ul className="check-list">
        <li>
          6 mm Float/16 mm ramka aluminiowa + argon/4 mm Float Ultra – Ug=1,1
          Rw=35 dB
        </li>
        <li>
          8 mm Float/16 mm ramka aluminiowa + argon/4 mm Float Ultra – Ug=1,1
          Rw=36 dB
        </li>
        <li>
          10 mm Float/16 mm ramka aluminiowa + argon/4 mm Float Ultra – Ug=1,1
          Rw=38 dB
        </li>
      </ul>
      <p>
        Należy pamiętać, aby szyby zostały zamontowane w ramach o równie dobrych
        właściwościach dźwiękochłonności. Istotny jest także sposób montażu w
        ramie, montaż stolarki w otworze, a nawet zastosowanie odpowiednich
        elementów dodatkowo polepszających dźwiękochłonność (np. nawiewników).
      </p>
      <p>
        Drugą metodą jest zastosowanie laminowanych szyb ze specjalnymi foliami
        akustycznymi, które poprawiają parametry tłumienia dobiegających z
        otoczenia hałasów. Laminowane szkło dźwiękochłonne pozwala również
        połączyć wysokie parametry izolacyjności dźwiękowej z wymogami
        bezpieczeństwa.
      </p>
      <p>
        Dla otrzymania określonej dźwiękochłonności przegrody oprócz
        dźwiękochłonności szyby, ważne są:
      </p>
      <ul className="check-list">
        <li>dźwiękochłonność ramy,</li>
        <li>sposób montażu szyby w ramie,</li>
        <li>prawidłowy, szczelny montaż okna w otworze,</li>
        <li>
          zastosowanie elementów dodatkowo obniżających dźwiękochłonność np.
          nawiewników.
        </li>
      </ul>
      <p>
        Szyby dźwiękochłonne można łączyć z szybami spełniającymi inne funkcje
        np. termoizolacyjnością, bezpieczeństwem, ochroną przeciwsłoneczną np.
        poprzez zastosowanie szyb z powłokami lub szyb laminowanych o określonej
        budowie.
      </p>
    </>
  );
};

export const HeatingContents = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-6 content-name">
          <h1>HEATING GLASS</h1>
          <p style={{ textAlign: "justify" }}>
            Szyby elektrogrzewcze są stosowane tam, gdzie konieczne jest
            zapobieganie zamarzaniu, tworzeniu się oblodzenia, a także
            zaparowywaniu szyb w celu zapewnienia dobrej widoczności w każdych
            warunkach pogodowych, nawet przy bardzo niskich temperaturach.
            Stosowane są również w zadaszeniach w celu szybszego pozbycia się
            zalegającego śniegu. Montowane są głownie w sterówkach statków,
            podgrzewanych dachach i przeszkleniach.
          </p>
        </div>
        <div className="col-md-6 content-image">
          <img className="main-image" src={heating} alt="HEATING GLASS" />
        </div>
      </div>
      <p>
        Szkło elektrogrzewcze produkowane jest na bazie hartowanego szkła
        laminowanego z dodatkowym elementem grzewczym między taflami. Minimalna
        grubość pakietu grzewczego to 2x6mm. Szyby elektrogrzewcze zapewniają
        niezmienioną widoczność, nie powodują zniekształceń optycznych ani
        zmiany barw otoczenia.
      </p>
      <p>
        Sterowanie temperaturą szyby odbywa się za pomocą regulatora lub
        termostatu. Regulacja temperatury może być oddzielna dla każdej szyby
        lub wspólna dla wszystkich w danym pomieszczeniu. W przypadku
        regulatora, szyba przy pomocy czujnika znajdującego się między taflami
        pakietu, dostosowuje się do danej, ręcznie ustawionej temperatury (max.
        40 st. C). Szyba przy zastosowaniu termostatu umieszczonego w puszce
        przyłączeniowej na krawędzi szyby, automatycznie dostosowuję się do
        danej temperatury (max 35 st. C). Moc grzewcza dobierana jest w
        zależności od warunków pracy i jest to 7-21 W/dm2 – zgodnie z normą,
        jednak dla naszych klientów możemy wyprodukować również szyby o innej
        mocy.
      </p>
      <h2>ZALETY</h2>
      <ul className="check-list">
        <li>zapobiega zamarzaniu, oblodzeniu i zaparowywaniu szyb,</li>
        <li>uniemożliwia zaleganie śniegu na dachach,</li>
        <li>zapewnia dobra widoczność,</li>
        <li>wyższa wytrzymałość i bezpieczeństwo,</li>
        <li>w przypadku zbicia mniejsze ryzyko zranienia.</li>
      </ul>
      <h2>OFERUJEMY SZYBY GRZEWCZE</h2>
      <ul className="check-list">
        <li>o minimalnych wymiarach formatki 400x600mm,</li>
        <li>o maksymalnych wymiarach formatki 5000x2000mm,</li>
        <li>
          o grubości od ok. 12mm (6+PVB+6) do ok. 44mm(19+PVB+25) lub większe wg
          indywidulanych potrzeb klienta,
        </li>
        <li>sterowane regulatorem lub termostatem,</li>
        <li>o maksymalnej temperaturze grzania 40 st. C,</li>
        <li>
          o standardowej mocy grzewczej od 7W/dm2 lub wyższej wg indywidulanych
          potrzeb klienta.
        </li>
      </ul>
    </>
  );
};

export const RadarProtectContents = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-6 content-name">
          <h1>RADAR PROTECT</h1>
          <p style={{ textAlign: "justify" }}>
            Technologia opracowana przez firmę pozwala na produkcję okien
            redukujących promieniowanie elektromagnetyczne w paśmie od kilku kHz
            do kilkudziesięciu GHz nawet 1000 krotnie (80dB).
          </p>
          <p style={{ textAlign: "justify" }}>
            Pośród wielu możliwych zastosowań szyb uwagę zwraca ich rola w
            eliminacji elektrosmogu, w tym niwelacji szkodliwego promieniowania
            masztów telefonii komórkowej BTS. Promieniowanie takie – jak
            potwierdzają opracowania naukowe i statystyki medyczne – jest
            przyczyną przewlekłych i śmiertelnych chorób. Liczba masztów BTS
            oraz innych źródeł szkodliwych promieniowań wzrasta o 10% każdego
            roku.
          </p>
        </div>
        <div className="col-md-6 content-image">
          <img className="main-image" src={eMagnetic} alt="RADAR PROTECT" />
        </div>
      </div>
      <p>
        Zastosowanie okien z szybami w budynkach użyteczności publicznej i
        domach mieszkalnych eksponowanych na szkodliwe promieniowanie znacznie
        podnosi zdrowotność pomieszczeń i komfort życia ich mieszkańców.
        Powszechność takich zastosowań jest kwestią najbliższej przyszłości.
      </p>
    </>
  );
};

export const PrivaControlContents = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-6 content-name">
          <h1>PRIVA CONTROL</h1>
          <p style={{ textAlign: "justify" }}>
            Szkło to wykonane jest ze specjalnej folii (LC film – Liquid Crystal
            film) o zmiennej przezierności zalaminowanej między dwiema taflami
            szkła. Sterowanie przepływem prądu przez folię pozwala zmieniać jej
            właściwości od przezroczystej (stan włączony) do nieprzezroczystej
            (stan wyłączony). Dzięki tej właściwości szyby Priva-Control są
            wykorzystywane do zapewnienia prywatności, zwiększenia
            bezpieczeństwa a także jako ekrany do wyświetlania obrazów i filmów.
            Szyby Priva Control mogą być montowane w ramach systemów
            aluminiowych, stalowych i drewnianych, mogą także być instalowane
            jako ekrany wiszące, ściany działowe, itp.
          </p>
        </div>
        <div className="col-md-6 content-image">
          <img className="main-image" src={priva} alt="PRIVA CONTROL" />
        </div>
      </div>
      <h2>WYMIARY</h2>
      <p>
        Standardowy maksymalny wymiar szyby 1400 x 3000mm, w pewnych przypadkach
        można wyko- nać szyby o wymiarze do 3500mm. Minimalny wymiar ok.
        100x100mm. Istnieje możliwość łączenia kilku folii w jednej szybie
        jednak miejsce połączenia będzie widoczne. Obróbkę krawędzi, wykonanie
        otworów lub wycięć należy ustalić przy zamówieniu, nie jest możliwa
        obróbka szyby po wykonaniu laminowania.
      </p>
      <h2>PODŁĄCZENIE ZASILANIA I STEROWANIA</h2>
      <p>
        Przełącznik powinien być instalowany przed transformatorem; z uwagi na
        skoki napięcia w sieci zalecamy zastosowanie UPS do stabilizacji
        napięcia. Sterowanie przełączaniem szyb może odbywać się za pomocą
        przełącznika, sterownika z pilotem lub układu automatyki. Stronę
        wyprowadzenia kabli przyłączeniowych należy ustalić w zamówieniu. Szyby
        Priva-Control® mogą być podłączane tylko do oryginalnego wyposażenia
        dostarczanego wraz z szybami.
      </p>
      <h2>PARAMETRY ELEKTRYCZNE</h2>
      <ul className="check-list">
        <li>zasilanie transformatora: 230 V AC, 50 Hz,</li>
        <li>zasilanie szyb: 60 V do 80 V AC 50 Hz.</li>
      </ul>
      <h2>MONTAŻ</h2>
      <p>
        Przy montażu należy używać materiałów neutralnych – wyłącznie podkładek
        z nieimpregnowanego drewna, jeżeli konieczne jest zastosowanie silikonu
        to jest on dostarczany przez firmę Bohamet. Mocowanie nie może wywierać
        nacisku na płaszczyznę szyby ani na jej krawędzie, szczególną uwagę
        należy zwrócić na takie prowadzenie kabli zasilających w ramie, aby nie
        wywierały nacisku na szyby i nie uległy uszkodzeniu.
      </p>
      <h2>UWAGI DODATKOWE</h2>
      <p>
        W przypadku szkleń zewnętrznych wymagana jest szyba zespolona i
        ogrzewanie pomieszczenia. Należy zapewnić wentylację transformatorom
        zasilającym i ewentualnie innym urządzeniom sterującym.
      </p>
    </>
  );
};

export const PlainGlassContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "SZKŁO BAZOWE",
        }}
        image={noImage}
      />
      <h2>HARTOWANE (ESG, FTG)</h2>
      <p>
        W procesie hartowania szkła w jego strukturze dokonują się zmiany
        naprężeń wewnętrznych, powodujące wzrost wytrzymałości na zginanie i
        inne czynniki mechaniczne oraz poprawia się bezpieczeństwo jego
        użytkowania. Szyby hartowane (ESG, FTG) są pięciokrotnie bardziej
        wytrzymałe na zginanie, zwiększa się również jego odporność na zmiany
        temperatury. W przypadku zbicia szkło rozpada się na małe cząstki o
        tępych krawędziach zmniejszając ryzyko zranienia. Dlatego też, szkło
        hartowane stosowane jest w miejscach szczególnie odpowiedzialnych,
        głównie w miejscach użyteczności publicznej, gdzie występuje zagrożenie
        rozbicia i skaleczenia lub np. w fasadach, które poddawane są silnemu
        nasłonecznieniu.
      </p>
      <p>Zalety:</p>
      <ul className="check-list">
        <li>
          pięciokrotnie wyższa wytrzymałość na zginanie i czynniki mechaniczne w
          porównaniu ze szkłem niehartowanym,
        </li>
        <li>
          wyższa wytrzymałość na szybkie zmiany temperatury otoczenia i
          nagrzewanie powodowane promieniowaniem słonecznym,
        </li>
        <li>w przypadku zbicia mniejsze ryzyko zranienia.</li>
      </ul>
      <h2>PÓŁHARTOWANE TVG</h2>
      <p>
        Szkło półhartowane TVG zwane również „wzmacnianym termicznie” jest
        poddawane podobnemu procesowi obróbki jak szyby hartowane, jednak
        odróżnia go rodzaj siatki spękań, jaka powstaje podczas zniszczenia
        tafli szkła. Naprężenia wewnętrzne szkła TVG są mniejsze, co powoduje
        powstanie większych kawałków szkła podczas zbicia, a w przypadku szyb
        montowanych w ramach zmniejszenie ryzyka wypadnięcia szyby z ramy lub
        innego mocowania, co zabezpiecza przed zranieniem. Szkło TVG jest zatem
        ciekawą alternatywą dla szkła hartowanego ESG, ale nie jest jednak
        zaliczane do szkieł bezpiecznych.
      </p>
      <p>Oferujemy szkło hartowane i półhartowane:</p>
      <ul className="check-list">
        <li>o grubości od 4 do 25mm,</li>
        <li>o minimalnych wymiarach formatki 215x215mm,</li>
        <li>o maksymalnych wymiarach formatki 2300x3600mm,</li>
        <li>z otworami i wycięciami,</li>
        <li>z krawędziami szlifowanymi lub polerowanymi,</li>
        <li>o rożnych kształtach: prostokąty, trójkąty, łuki i koła,</li>
        <li>
          w połączeniu z innym szkłem lub poddane dalszej obróbce: laminowanie,
          zespalanie itp.
        </li>
      </ul>
      <p>
        <strong>
          Szyby na statki spełniają wymagania norm ISO 614, PN-88/W88059, ISO
          3254, ISO 1095.
        </strong>
      </p>
      <p>
        <strong>
          Szyby do budownictwa spełniają wymagania normy PN-EN 12150-1, PN-EN
          1863
        </strong>
      </p>
      <h2>LAMINOWANE (VSG I VSG/ESG)</h2>
      <p>
        Szyby laminowanie powstają w skutek połączenia na stałe dwóch lub wielu
        szyb pojedynczych ze sobą. Szyby sklejane są na całej powierzchni przy
        użyciu co najmniej jednej warstwy specjalnej folii poliwinylobutyralowej
        (PVB) lub kopolimeru etylenu z octanem winylu (EVA) w warunkach
        wysokiego ciśnienia i temperatury.
      </p>
      <p>
        Laminować ze sobą można szyby o różnych grubościach hartowane lub
        niehartowane. W skutek sklejenia dwóch lub więcej szyb niehartowanych
        otrzymujemy szkło bezpieczne. W przypadku zbicia jednej szyby lub całego
        pakietu odłamki szklane pozostają na miejscu, nie rozpryskując się,
        zmniejszając tym samym ryzyko pokaleczenia. By jeszcze bardziej
        zwiększyć odporność szyb laminowanych bezpiecznych na uderzenia do ich
        budowy można wykorzystać szkło hartowane – ESG lub wzmacniane termicznie
        – TVG. Dzięki laminowaniu ze sobą szyb o różnych grubościach można
        uzyskać pakiety o bardzo dużej grubości, odporne na wysokie ciśnienia.
      </p>
      <p>
        Przy zastosowaniu specjalnych folii szyby laminowane mogą spełniać
        funkcje: antywłamaniowe i kuloodporne, dźwiękochłonne oraz stanowić
        ochronę przed eksplozją ładunków wybuchowych i falą uderzeniową.
      </p>
      <p>
        Istnieje możliwość laminowania ze sobą szkła o różnym zabarwieniu,
        dzięki czemu można otrzymać pakiet o innym niż w przypadku szkła
        bezbarwnego współczynniku przenikania światła.
      </p>
      <p>
        Szyby mogą być laminowane z dodatkowym elementem wewnątrz. Elementem
        takim może być element grzewczy. W wyniku zlaminowania szyb z elementem
        grzewczym wewnątrz otrzymujemy szyby elektrogrzewcze.
      </p>
      <p>
        Zalaminować można również folię z nadrukiem lub tafle szkła, z których
        jedna pokryta jest drukiem cyfrowym tworząc dekoracyjne szkło z dowolną
        grafiką czy zdjęciem. Dodatkowym elementem w laminacji szkła mogą być
        także tkaniny i siatki metaliczne.
      </p>
      <p>
        Szkło oraz folia mogą być różne pod względem kolorystyki oraz grubości i
        zaprojektowane tak, aby spełniać odpowiednie normy i wymagania klienta
        oraz przepisów budowlanych.
      </p>
      <p>Oferujemy szkło laminowane:</p>
      <ul className="check-list">
        <li>o minimalnych wymiarach formatki 215x215mm,</li>
        <li>o maksymalnych wymiarach formatki 2500x4800mm,</li>
        <li>o łącznej grubości od 8 do 34mm,</li>
        <li>z otworami i wycięciami,</li>
        <li>z krawędziami szlifowanymi lub polerowanymi,</li>
        <li>bezbarwne lub kolorowe,</li>
        <li>
          funkcyjne: kuloodporne, dźwiękochłonne, elektrogrzewcze, dekoracyjne.
        </li>
      </ul>
      <p>
        <strong>
          Szyby do budownictwa spełniają wymagania normy PN-EN 12543
        </strong>
      </p>
      <h2>ZESPOLONE</h2>
      <p>
        Szyba zespolona składa się z dwóch (jednokomorowa) lub więcej (dwu lub
        wielokomorowe) szyb składowych, których krawędzie na całym obwodzie są
        zespolone i uszczelnione przy użyciu specjalnej ramki dystansowej i
        szczeliw tworząc przestrzeń międzyszybową. Poszczególne składowe szyby
        mogą być różnej grubości.
      </p>
      <p>
        Szyby zespalane w przeciwieństwie do szyb monolitycznych
        jednowarstwowych charakteryzują się przede wszystkim lepszą
        izolacyjnością cieplną. Szyby zespolone o podstawowym współczynniku
        przenikalności cieplnej posiadają miedzy składowymi szybami komorę
        powietrzną. Rozwiązanie takie zapewnia stosunkowo dobre wyniki. Tak
        zbudowana szyba chroni w stopniu podstawowym przed utratą ciepła z
        pomieszczenia lub skutecznie uniemożliwia jego przenikanie do wnętrza
        pomieszczenia – zależnie od pory roku – nie powodując tym samym
        zniekształceń optycznych i nie będąc barierą dla światła. Szyby o
        podwyższonym współczynniku izolacyjności przestrzeń pomiędzy szybami
        mają wypełniana gazem szlachetnym (argon), dzięki czemu przenikanie
        promieniowania cieplnego przez szybę jest bardzo niskie. Efektywność
        szyb zespolonych w zakresie energooszczędności jest jeszcze większa, gdy
        stosuje się je w połączeniu z niskoemisyjnym i/lub selektywnym szkłem
        powlekanym. Komorowa budowa szyb zespolonych poza właściwościami
        izolacyjnymi zapewnia również możliwość wykorzystania szyb zespolonych
        jako ekrany akustyczne.
      </p>
      <p>
        W przypadku zespolenia z szybami specjalistycznymi możemy uzyskać
        również szyby m.in. ognioodporne i kuloodporne.
      </p>
    </>
  );
};

export const SpraytightContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Spraytight",
          h2Content: [
            "The spraytight ship doors and electrical spraytight ship doors are designed to be mounted in the walls of ship’s superstructures.",
          ],
        }}
        image={spraytight}
      />
    </>
  );
};

export const WeathertightContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Weathertight",
          h2Content: [
            "The door can be made of steel, aluminum or stainless steel. Pins of closures are settled in steel sleeves. Hinge pins are made from stainless steel.",
          ],
        }}
        image={weathertight}
      />
    </>
  );
};

export const WatertightContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Watertight",
          h2Content: [
            "The electrical spray tight ship double door is designed mainly for mounting in walls of ship’s superstructures.",
          ],
        }}
        image={watertight}
      />
    </>
  );
};

export const YachtContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Yacht",
          h2Content: [
            "The weathertight yacht doors are designed for mounting in walls of yacht’s superstructures.",
          ],
        }}
        image={yacht}
      />
    </>
  );
};

export const LightweightContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Lightweight",
          h2Content: [
            "Lightweight door can be used in areas onboard a ship or platform where a fire class single door is needed.",
          ],
        }}
        image={lightweight}
      />
    </>
  );
};

export const FireRatedContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Fire rated",
          h2Content: [
            "Weathertight door are designed to be mounted in ship superstructures’ walls.",
          ],
        }}
        image={fireRated}
      />
    </>
  );
};

export const NonTypicalContents = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Non typical",
          h2Content: [
            "External ship door is designed mainly for mounting in walls of ships for cabin which necessary is ongoing access to fresh air and not important is watertightness – for example a warehouse.",
          ],
        }}
        image={nonTypical}
      />
    </>
  );
};
