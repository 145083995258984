import { ContentHeader } from "../../components/Content/ContentHeader";
import interiorWallSystem from "../../assets/interior_wall_system.jpg";
import internalWalls from "../../assets/internal_walls.jpg";
import partitionWalls from "../../assets/partition_walls.jpg";
import driversDesk from "../../assets/drivers_desk.jpg";
import ceilings from "../../assets/ceilings.jpg";
import driversCabDoor from "../../assets/drivers_cab_door.jpg";
import internalDoors from "../../assets/internal_doors.jpg";
import passengerDoor from "../../assets/passenger_door.jpg";
import internalDoorDriversFence from "../../assets/internal_door_drivers_fence.jpg";
import tiltWindow from "../../assets/tilt_window.jpg";
import driversCabWindow from "../../assets/drivers_cab_window.jpg";
import hingedWindow from "../../assets/hinged_window.jpg";
import slidingWindow from "../../assets/sliding_window.jpg";
import dropdownSlidingWindow from "../../assets/dropdown_sliding_window.jpg";

export const InteriorWallSystemsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Interior wall systems with door",
          h3Content: [
            "Internal walls with doors for all types of vehicles dedicated to public transport. The main applications of these products are trains, underground and trams.",
          ],
        }}
        image={interiorWallSystem}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h4>
        The customer can adjust the structure of internal walls with doors to
        their own environment – this is the greatest advantage of this product.
      </h4>
      <p>
        All customer requirements are agreed at the stage of creating the
        structure, which allows the customer to have full insight into the
        product before starting serial production.
      </p>
      <p>
        The walls are manufactured as lightweight aluminum structures with
        glass, sheet or honeycomb filling. Various materials can be used for
        surfaces visible to passengers:
      </p>
      <ul className="check-list">
        <li>aluminum</li>
        <li>laminate</li>
      </ul>
      <p>Applicability:</p>
      <ul className="check-list">
        <li>additional bottom guide</li>
        <li>ventilation grilles</li>
        <li>photocells</li>
      </ul>
      <h4>The doors are mainly equipped with an electric or manual drive.</h4>
      <p>
        Internal door systems meet the most diverse functional requirements
        imposed by public transport, while ensuring the safety of passengers,
        the systems are easy to install and service.
      </p>
      <h4>
        For the production of internal door systems, we use aluminum alloys and
        modern-hybrid materials that combine the properties of many different
        materials, allowing for a low product weight, high strength parameters,
        as well as a fire barrier.
      </h4>
      <p>
        Depending on the specification, the systems can be electric, pneumatic
        or manual. Internal door systems can be finished as satin, brushed or
        finished with paint coatings, decorative elements and HPL layers.
      </p>
    </>
  );
};

export const InternalWallsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Internal walls",
          h3Content: [
            "Internal structures and stairs with equipment, intended for passenger carriages, electric and combustion units.",
          ],
        }}
        image={internalWalls}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h2>Basic technical data:</h2>
      <p>
        Structures of internal structures made of aluminum alloys are both
        structural (load-bearing) and decorative elements. These products are
        intended for connection and cooperation with other vehicle components,
        e.g. air conditioning systems, electrical cabinets, etc.
      </p>
      <p>
        Depending on the specification and aesthetic values, the following can
        be used for the production of bodies: aluminum alloys, HPL layers,
        veneers, honeycomb (honeycomb), cork, as well as plastics and GRP. There
        are also versions with additional acoustic, thermal and vibration
        insulation.
      </p>
      <p>
        We offer surface finishing in the form of powder or liquid painting. At
        the customer’s request, places for the installation of passenger
        information devices are also prepared in the development.
      </p>
      <h2>Additional information:</h2>
      <ul className="check-list">
        <li>made of aluminum</li>
        <li>modern styling</li>
      </ul>
      <h2>Wall protective coating:</h2>
      <ul className="check-list">
        <li>liquid painting</li>
        <li>powder painting</li>
      </ul>
    </>
  );
};

export const PartitionWallsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Partition walls",
          h3Content: ["All kinds of walls for public transport vehicles."],
        }}
        image={partitionWalls}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>aluminum, glass, HPL, GRP, honeycomb (honeycomb)</li>
        <li>swinging or automatic doors</li>
        <li>can be combined with additional equipment</li>
        <li>the possibility of using many materials</li>
        <li>thickness up to 25 mm.</li>
        <li>lightweight construction</li>
      </ul>
      <p>
        Internal walls are made of glass, aluminum and HPL laminates. The
        structures of walls and internal buildings, mainly made of aluminum
        alloys, are decorative elements and load-bearing structures. These
        products are intended for connection and cooperation with other vehicle
        components, e.g. air-conditioning systems, electrical cabinets, etc.
      </p>
      <p>
        Depending on the specification and aesthetic values ​​- aluminum alloys,
        glass, HPL layers, veneers, honeycomb (honeycomb), cork, plastics and
        GRP can be used for the production of bodies.
      </p>
      <p>
        There are also versions with additional acoustic, thermal and vibration
        insulation. We offer surface finishing in the form of powder or liquid
        painting. At the customer’s request, places for the installation of
        passenger information devices are also prepared in the development.
      </p>
      <p>
        Internal walls can have a different design and surface finish – all
        according to the customer’s requirements.
      </p>
      <p>
        In walls where the design provides for the use of doors, it is possible
        to use a swing system with a door closer or a sliding system with an
        electric mechanism.
      </p>
      <p>
        Internal walls that do not require fire resistance are also
        characterized by a small thickness of about 25 mm.
      </p>
      <p>
        Internal walls of the vehicle are relatively light and durable products
        due to the materials used in accordance with applicable standards.
      </p>
    </>
  );
};

export const DriversDeskContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Driver's desk",
          h3Content: [
            "The driver's desk is an element made as an aluminum structure tailored to the Customer's requirements.",
          ],
        }}
        image={driversDesk}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>durable construction</li>
        <li>full design and technological support</li>
        <li>design adapted to the Customer's requirements</li>
      </ul>
      <h2>Basic technical data:</h2>
      <p>
        The structure is equipped with inspection holes, prepared for the
        installation of additional accessories, such as LCD monitors, devices
        for controlling and measuring the operation of the locomotive or cable
        harnesses. Depending on the structure and customer requirements, the
        design may include elements made of laminate (GRP), as well as the
        preparation of marking for control and measurement devices.
      </p>
      <p>
        The product is offered on a “build to print” basis or with full
        construction and technological support at every stage of design.
      </p>
    </>
  );
};

export const CeilingsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Ceilings",
          h3Content: [
            "Ceiling structures are made of aluminum alloys as load-bearing structures or decorative elements only.",
          ],
        }}
        image={ceilings}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
        <li>Bus</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>plug and play</li>
        <li>additional thermal insulation</li>
        <li>passenger information systems</li>
        <li>elements supporting people with hearing disabilities</li>
        <li>vibration damping</li>
        <li>lighting systems in accordance with EN, DIN, BS, NF</li>
        <li>information systems</li>
      </ul>
      <h2>Basic technical data:</h2>
      <p>
        Structurally, the products provide for connections with other vehicle
        devices, e.g. air-conditioning systems. In the standard version, the
        ceilings are ready-to-use elements in the vehicle on a plug and play
        basis. Depending on the shape and aesthetic requirements, the ceilings
        are made of sandwiches, profiles, aluminum sheets or a combination of
        both solutions. There are also versions with additional thermal,
        acoustic and vibration insulation.
      </p>
      <p>
        Ceiling structures, as ready-to-install elements, are equipped with
        lighting systems (in accordance with EN, DIN, BS, NF), passenger
        information systems, information systems, elements supporting people
        with hearing disabilities.
      </p>
    </>
  );
};

export const DriversCabDoorContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "External door",
          h2Content: ["Driver's cab door"],
        }}
        image={driversCabDoor}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>PN EN 45545</li>
        <li>window lock in any position</li>
        <li>inside laminates</li>
        <li>solid construction</li>
        <li>possibility of using recessed pockets for handles and locks</li>
      </ul>
      <h5>The driver’s cab door systems assume 2 delivery options:</h5>
      <ul className="check-list">
        <li>frame + door leaf</li>
        <li>only the door leaf</li>
      </ul>
      <p>
        The base of the leaves is made of a frame made of aluminum profiles,
        covered with a metal sheathing on the outside and containing special
        insulating layers that improve thermal and acoustic parameters.
        Depending on the customer’s needs, the inside is made of metal sheets or
        laminates (polyester-glass or thermoformed). The external shape of the
        products is adapted to the vehicle structure profile. The grade and
        state of hardening of the materials used in production are each time
        adjusted to the specific strength requirements of the customer. The
        surface of the panels (inside and outside) is protected with varnish in
        the liquid or powder painting system (depending on the door production
        technology) according to the Customer’s requirements and colors. Door
        frames made of steel or aluminum materials. Door leaves are made of
        lightweight construction materials.
      </p>
      <p>
        Depending on the needs of the project, the door can be prepared without
        a window or equipped with a window:
      </p>
      <ul className="check-list">
        <li>fixed;</li>
        <li>hinged;</li>
        <li>lowered;</li>
      </ul>
      <p>
        A characteristic feature of the driver’s cabin door is the lock system
        enabling a wide range of adaptation to the customer’s design
        requirements:
      </p>
      <ul className="check-list">
        <li>1- or 2-door handles from the outside;</li>
        <li>1- or 2- or 3-point locking system;</li>
        <li>pressure tightness function;</li>
        <li>anti-panic function;</li>
        <li>
          the possibility of using various types of inserts (from standard
          patents to special systems);
        </li>
      </ul>
    </>
  );
};

export const InternalDoorsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Internal doors",
        }}
        image={internalDoors}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>lightweight structures</li>
        <li>the possibility of choosing the material and glazing</li>
        <li>modern design</li>
      </ul>
      <h4>
        The external shape is adapted to the profile of the vehicle structure
      </h4>
      <p>
        The grade and state of hardening of the materials used in production are
        each time adjusted to the specific strength requirements of the
        customer. The sealing of the system is made of a series of specially
        shaped rubber profiles that provide protection against the ingress of
        water and dust into the interior of the vehicle. The surface of the
        panels (inside and outside) is protected with varnish in the liquid
        painting system according to the customer’s requirements and colors.
      </p>
      <p>
        As standard, a window with a safety glass package – glued or mounted on
        a seal – made according to the project requirements.
      </p>
      <h4>Each door is equipped with passenger jamming protection systems.</h4>
      <p>
        Depending on the specific customer requirements, the system is equipped
        with:
      </p>
      <ul className="check-list">
        <li>buttons for operating the door;</li>
        <li>emergency exit handles;</li>
        <li>sound and light signals;</li>
        <li>floor or chassis steps;</li>
      </ul>
      <p>
        In terms of standardization, our door systems meet the requirements of
        standards in the field of strength, impact strength, electromagnetism
        and interoperability standards.
      </p>
    </>
  );
};

export const PassengerDoorContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Passenger door",
          h2Content: ["sliding plug"],
        }}
        image={passengerDoor}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>electric drive</li>
        <li>passenger safety system (protection against jamming)</li>
        <li>pneumatic drive</li>
      </ul>
      <p>
        The driving unit of each system is a door mechanism – electric or
        pneumatic – pre-calibrated, with a complete cable system and
        functionally and mechanically checked. The compact design and the
        planned movement path of the internal components enable quick and
        trouble-free assembly within the portal area of ​​the vehicle and reduce
        the need for adjustments for correct alignment. Door leaves are made of
        lightweight construction materials. The base of the wings is made of a
        frame made of aluminum profiles, covered with a sheet metal sheathing
        and containing special insulating layers that improve thermal and
        acoustic parameters.
      </p>
      <p>
        Depending on the specification, the systems can be electric, pneumatic
        or manual. The systems can be satin or brushed or finished with paint
        coatings, decorative elements and HPL layers.
      </p>
    </>
  );
};

export const InternalDoorDriversFenceContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Internal door driver's fence",
        }}
        image={internalDoorDriversFence}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>modern design</li>
        <li>lightweight construction</li>
        <li>easy assembly and service</li>
        <li>the possibility of using many materials</li>
        <li>durable door system</li>
      </ul>
      <p>
        Internal door systems meet the most diverse functional requirements
        required by public transport, while ensuring the highest possible levels
        of driver safety. The systems are easy to install and service.
      </p>
      <p>
        For the production of internal door systems, we use aluminum alloys and
        modern-hybrid materials that combine the properties of many different
        materials, allowing for a low product weight, high strength parameters,
        as well as a fire barrier. Depending on the specification, the systems
        can be either pneumatic or manual.
      </p>
      <p>
        Driver’s internal door systems can be finished in various ways: satin,
        brushed or finished with paint coatings, decorative elements and HPL
        layers.
      </p>
    </>
  );
};

export const TiltWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Tilt window",
        }}
        image={tiltWindow}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h4>Tilt windows can be made of welded or bolted aluminum profiles.</h4>
      <p>
        Both the fixed and tilt glass can be made of tempered or laminated
        safety glass. It is also possible to use windows with single and glazed
        units in packages. Windows with the applied glazing units have increased
        thermal insulation and favorable optoenergy parameters.
      </p>
      <h4>
        We are a manufacturer of windows intended to be pasted into the vehicle
        body and windows with the use of screwed constructions that enable the
        quick replacement of the entire window on the vehicle.
      </h4>
      <p>
        The window frames are powder coated in accordance with the applicable
        rail market standards. The tightness of the windows is achieved with the
        use of silicone, brush and flocked seals that can withstand many years
        of operation. The panes in the windows are mounted with gaskets or glued
        in accordance with DIN 6701, class A1. The drop-down part of the window
        can be equipped with locks that can be locked with a conductor key. All
        aluminum frames are welded in accordance with the requirements of ISO
        4063. The windows meet the water tightness requirements in accordance
        with PN-EN 14752 and EN 50125. The tilting movement of the window takes
        place through cooperating aluminum profiles with an appropriate
        cross-sectional shape.
      </p>
      <p>
        For the comfort of passengers, our windows are equipped with a balancing
        mechanism that allows it to be kept in any position. The window design
        includes drainage holes for water condensation inside the window to the
        outside of the vehicle.
      </p>
      <h2>Possible glazing applications:</h2>
      <ul className="check-list">
        <li>tempered glass,</li>
        <li>laminated glass,</li>
        <li>double glazing,</li>
        <li>low-emission glazing,</li>
        <li>matte glass.</li>
      </ul>
    </>
  );
};

export const DriversCabWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Driver's cab window",
        }}
        image={driversCabWindow}
      />
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>Incredible comfort for the driver</li>
        <li>Modern design</li>
        <li>Simple operation</li>
      </ul>
      <h4>The unique compact design in one form includes two modules:</h4>
      <p>
        Hinged aluminum window with a glazed unit fitted with an opening limiter
        function and a gas spring that keeps the window open.
      </p>
      <p>
        Mirror opened pneumatically based on an aluminum structure with an
        electrically heated and controlled mirror.
      </p>
      <h4>
        The entire structure is characterized by aesthetic and modern design,
        reliability and aesthetics of workmanship.
      </h4>
    </>
  );
};

export const HingedWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Hinged window",
        }}
        image={hingedWindow}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
      </ul>
      <h4>Hinged windows are made of aluminum frames welded together.</h4>
      <p>
        Both the fixed and the tilted part of the window can be made of tempered
        or laminated safety glass. It is possible to use windows with single and
        glazed units in packages. Windows with the applied glazing units have
        increased thermal insulation and favorable optoenergy parameters.
      </p>
      <p>
        The window frames are powder coated in accordance with the applicable
        rail market standards. The tightness of the windows is achieved by means
        of rubber seals that can withstand many years of operation. The panes in
        the windows are mounted with gaskets or glued in accordance with DIN
        6701, class A1. The drop-down part of the window can be equipped with
        locks that can be locked with a conductor key. All aluminum frames are
        welded in accordance with the requirements of ISO 4063. The windows meet
        the water tightness requirements in accordance with the PN-EN 14752 and
        EN 50125 standards. The window is closed with a plastic handle, and the
        espagnolette is responsible for keeping the window in the closed
        position. The window is equipped with a gas spring that keeps the window
        stable in the maximum open and closed position. The window design
        includes drainage holes for water condensation inside the window to the
        outside of the vehicle.
      </p>
      <h2>Possible glazing applications:</h2>
      <ul className="check-list">
        <li>toughened glass,</li>
        <li>laminated glass,</li>
        <li>double glazing,</li>
        <li>low-emission glazing,</li>
        <li>matte glass.</li>
      </ul>
    </>
  );
};

export const SlidingWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Sliding window",
          h2Content: [
            "Modern design combined with incredible comfort and safety of travelers.",
          ],
        }}
        image={slidingWindow}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Tram</li>
        <li>Train</li>
        <li>Bus</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>powder coated</li>
        <li>conductor key lock</li>
        <li>PN-EN 14752</li>
        <li>DIN 6701 (A1)</li>
        <li>ISO 4063</li>
        <li>EN 50125</li>
      </ul>
      <h4>
        Constructed sliding windows can be made of aluminum profiles welded or
        bolted together.
      </h4>
      <p>
        Both the fixed and the sliding glass can be made of tempered or
        laminated safety glass. It is also possible to use windows with single
        and glazed units in packages. Windows with the applied glazing units
        have increased thermal insulation and favorable optoenergy parameters.
      </p>
      <p>
        The window frames are powder coated in accordance with the applicable
        rail market standards. The tightness of the windows is achieved with the
        use of silicone, brush and flocked seals that can withstand many years
        of operation. The panes in the windows are mounted with gaskets or glued
        in accordance with DIN 6701, class A1. The drop-down part of the window
        can be equipped with locks that can be locked with a conductor key. All
        aluminum frames are welded in accordance with the requirements of ISO
        4063. The windows meet the requirements of water tightness in accordance
        with the PN-EN 14752 and EN 50125 standards. The sliding movement of the
        window takes place via plastic guides
      </p>
      <p>
        For the comfort of passengers, our windows are equipped with a balancing
        mechanism that allows it to be kept in any position. The window design
        includes drainage holes for water condensation inside the window to the
        outside of the vehicle.
      </p>
    </>
  );
};

export const DropdownSlidingWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Drop-down sliding window",
          h2Content: [
            "Modern design combined with incredible comfort and safety of travelers.",
          ],
        }}
        image={dropdownSlidingWindow}
      />
      <h2>Application:</h2>
      <ul className="check-list">
        <li>Train</li>
      </ul>
      <h2>Key features:</h2>
      <ul className="check-list">
        <li>powder coated</li>
        <li>conductor key lock</li>
        <li>PN-EN 14752</li>
        <li>DIN 6701 (A1)</li>
        <li>ISO 4063</li>
        <li>EN 50125</li>
      </ul>
      <h4>Drop-down windows are made of aluminum profiles welded together.</h4>
      <p>
        Both the fixed and the lowered glass can be made of tempered or
        laminated safety glass. It is also possible to use windows with single
        and glazed units in packages. Windows with the applied glazing units
        have increased thermal insulation and favorable optoenergy parameters.
      </p>
      <p>
        The window frames are powder coated in accordance with the applicable
        rail market standards. The tightness of the windows is achieved with the
        use of silicone, brush and flocked seals that can withstand many years
        of operation. The panes in the windows are mounted with gaskets or glued
        in accordance with DIN 6701, class A1. The drop-down part of the window
        can be equipped with locks that can be locked with a conductor key. All
        aluminum frames are welded in accordance with the requirements of ISO
        4063. The windows meet the requirements of water tightness in accordance
        with PN-EN 14752 and EN 50125. The sliding movement of the window
        downwards can take place through plastic guides
      </p>
      <p>
        To achieve the maximum level of passenger comfort, our windows are
        equipped with a balancing mechanism that allows you to keep it in any
        position. The window design includes drainage holes for water
        condensation inside the window to the outside of the vehicle.
      </p>
    </>
  );
};

export const MultipleUnitsEquipmentContent = () => {
  return (
    <>
      <h1 className="section-header">Multiple units equipment</h1>
      <ul className="check-list">
        <li>Wheelset</li>
        <li>Air spring system</li>
        <li>Rubber-metal bush</li>
        <li>Primary spring</li>
        <li>Wedge package</li>
        <li>Coil spring support</li>
        <li>Floorings compatible with the EN45545 standard</li>
        <li>Anti-roll bar systems</li>
        <li>Bearings</li>
        <li>Wheels</li>
        <li>Ventilation / HVAC systems</li>
        <li>Shock absorbers</li>
        <li>Break pads</li>
        <li>Vibro-isolators</li>
        <li>Bumpers</li>
        <li>Guiding bush</li>
        <li>Center pin bush</li>
        <li>Traction rod</li>
        <li>Motor unit pad</li>
      </ul>
    </>
  );
};

export const LocomotivesEquipmentContent = () => {
  return (
    <>
      <h1 className="section-header">Locomotives equipment</h1>
      <ul className="check-list">
        <li>Wheelset</li>
        <li>Rubber-metal bush</li>
        <li>Bearings</li>
        <li>Coil spring support</li>
        <li>Floorings compatible with the EN45545 standard</li>
        <li>Wheels</li>
        <li>Ventilation / HVAC systems</li>
        <li>Shock absorbers</li>
        <li>Break pads</li>
        <li>Traction rod</li>
        <li>Motor unit pad</li>
      </ul>
    </>
  );
};
