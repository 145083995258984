import useCollapse from "react-collapsed";
import "./sidebar.css";
import down from "../../assets/down.png";
import up from "../../assets/up.png";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export const Sidebar = ({ options }) => {
  return (
    <div className="sidebar">
      {options?.map((option) => {
        const { header, content } = option;
        return (
          <ToggleOption
            key={header.headerName}
            header={{ ...header }}
            content={content}
          />
        );
      })}
    </div>
  );
};

export const ToggleOption = ({ header, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { headerName, headerLink } = header;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="header" onClick={handleToggle}>
        {content?.length === 0 ? (
          <span>
            <a className="header-link" href={headerLink}>
              {headerName}
            </a>
          </span>
        ) : (
          <span>{headerName}</span>
        )}
        {content.length > 0 && (
          <span className="arrow">
            {isExpanded ? (
              <img src={up} alt="up arrow" />
            ) : (
              <img src={down} alt="down arrow" />
            )}
          </span>
        )}
      </div>
      <CollapsibleContent
        content={content}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
    </>
  );
};

export const CollapsibleContent = ({ content, isExpanded, setIsExpanded }) => {
  const { getCollapseProps } = useCollapse({ isExpanded });

  useEffect(() => {
    for (let i = 0; i < content?.length; i++) {
      if (content[i].isActive) {
        setIsExpanded(true);
        break;
      }
    }
  }, []);

  return (
    <div {...getCollapseProps()}>
      {content.map((contentPart) => {
        const { name, link, isActive } = contentPart;
        return (
          <a
            key={name}
            href={link}
            style={isActive ? { color: "#82aae3" } : {}}
          >
            <div className="content">{name}</div>
          </a>
        );
      })}
    </div>
  );
};

ToggleOption.propTypes = {
  header: PropTypes.object.isRequired,
  content: PropTypes.array,
};
