import "./content.css";

export const ContentHeader = ({ headerContent, image }) => {
  return (
    <div className="row">
      <div className="col-md-6 content-name">
        <h1>{headerContent?.h1Content}</h1>
        {headerContent?.h2Content?.map((header) => {
          return <h2 key={header}>{header}</h2>;
        })}
        {headerContent?.h3Content?.map((header) => {
          return <h3 key={header}>{header}</h3>;
        })}
      </div>
      <div className="col-md-6 content-image">
        <img
          className="main-image"
          src={image}
          alt={headerContent?.h1Content}
        />
      </div>
    </div>
  );
};
