import "./navbar.css";
import logo from "../../assets/logo.png";

const Navbar = ({ activeLink }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-custom">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            id="logo"
            src={logo}
            alt=""
            width="243"
            height="90"
            className="d-inline-block mx-2"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-end">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeLink === "home-link" ? "active" : ""
                }`}
                aria-current="page"
                href="/"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeLink === "company-link" ? "active" : ""
                }`}
                href="/company"
              >
                Company
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeLink === "products-link" ? "active" : ""
                }`}
                href="/products-and-services"
              >
                Products & Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeLink === "contact-link" ? "active" : ""
                }`}
                href="/contact"
                tabIndex="-1"
                aria-disabled="true"
              >
                Contact
              </a>
            </li>
            <li className="nav-item">
              <div className="switch-lang">
                <div className="current-lang">
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png"
                    alt="united-kingdom-flag"
                    className="lang-flag"
                  />
                  <p className="lang-text">English</p>
                </div>
                <div className="lang-dropdown">
                  <div className="selecting-lang">
                    <img
                      src="https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_Poland.png"
                      alt="poland-flag"
                      className="lang-flag"
                    />
                    <p className="lang-text">
                      <a
                        className="switcher-link"
                        href="https://www.mva-industries.pl"
                      >
                        Polski
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
