import { ContentHeader } from "../../components/Content/ContentHeader";
import "../../components/Content/content.css";
import knifeGateValveAB from "../../assets/4.2.png";
import knifeGateValveD from "../../assets/4.3.png";
import knifeGateValveGH from "../../assets/4.4.png";
import knifeGateValveGL from "../../assets/4.5.png";
import knifeGateValveC from "../../assets/4.6.png";

export const ValvesContents = ({ content }) => {
  return (
    <>
      <ContentHeader
        headerContent={content.headerContent}
        image={content.image}
      />
      {content.mainContent.Design && (
        <>
          <h1 className="section-header">Design</h1>
          <ul className="check-list">
            {content.mainContent.Design.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Applications && (
        <>
          <h1 className="section-header">Applications</h1>
          <ul className="check-list">
            {content.mainContent.Applications.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Media && (
        <>
          <h1 className="section-header">Media</h1>
          <ul className="check-list">
            {content.mainContent.Media.map((li, index) => {
              if (index === 0) {
                return <p key={li}>{li}</p>;
              } else {
                return <li key={li}>{li}</li>;
              }
            })}
          </ul>
        </>
      )}
      {content.mainContent.Pressure && (
        <>
          <h1 className="section-header">Pressure and temperature</h1>
          <ul className="check-list">
            {content.mainContent.Pressure.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Materials && (
        <>
          <h1 className="section-header">Materials</h1>
          <ul className="check-list">
            {content.mainContent.Materials.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Options && (
        <>
          <h1 className="section-header">Options</h1>
          <ul className="check-list">
            {content.mainContent.Options.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Advantages && (
        <>
          <h1 className="section-header">Advantages</h1>
          <ul className="check-list">
            {content.mainContent.Advantages.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.IndustrialApplications && (
        <>
          <h1 className="section-header">Industrial Applications</h1>
          <ul className="check-list">
            {content.mainContent.IndustrialApplications.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.ProductDescription && (
        <>
          <h1 className="section-header">Product description</h1>
          <strong>{content.mainContent.ProductDescription.header}</strong>
          <p
            style={{
              fontSize: "large",
              marginTop: "1.2rem",
              marginBottom: "1.2rem",
            }}
          >
            {content.mainContent.ProductDescription.description}
          </p>
        </>
      )}
      {content.mainContent.Sizes && (
        <>
          <h1 className="section-header">Sizes</h1>
          <ul className="check-list">
            {content.mainContent.Sizes.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.WorkingPressures && (
        <>
          <h1 className="section-header">Working pressures</h1>
          <ul className="check-list">
            {content.mainContent.WorkingPressures.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
    </>
  );
};

export const KnifeGateValveABContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Knife Gate Valve –  Type AB" }}
        image={knifeGateValveAB}
      />
      <h1 className="section-header">Application</h1>
      <ul className="check-list">
        <li>Food industry</li>
        <li>Chemical industry</li>
        <li>Water treatment</li>
        <li>Paper pulp industry</li>
        <li>Dams and Hydrological Projects</li>
      </ul>
      <h1 className="section-header">Product description</h1>
      <strong>Slurry valves, knife gate valve</strong>
      <p>
        Slurry valves, bidirectional wafer-design knife gate valve. One-piece
        cast body. Provides high flow rates with low pressure drop. Various seat
        and packing materials available.
      </p>
      <h1 className="section-header">Designed for applications such as:</h1>
      <ul className="check-list">
        <li>Chemical plants</li>
        <li>Pumping</li>
        <li>Food Industry</li>
        <li>Sewage treatment</li>
      </ul>
      <h1 className="section-header">Sizes</h1>
      <ul className="check-list">
        <li>Standard manufacturing sizes: From DN50 up to DN 600</li>
        <li>Bigger sizes under request.</li>
      </ul>
      <h1 className="section-header">Working pressures</h1>
      <ul className="check-list">
        <li>DN 50-DN200 = 10 bar</li>
        <li>DN 250-DN400 = 6 bar</li>
        <li>DN 450 = 5 bar</li>
        <li>DN 500 = 4 bar</li>
        <li>DN 600 = 3 bar</li>
      </ul>
    </>
  );
};

export const KnifeGateValveDContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Knife Gate Valve –  Type D" }}
        image={knifeGateValveD}
      />
      <h1 className="section-header">Application</h1>
      <ul className="check-list">
        <li>Food industry</li>
        <li>Chemical industry</li>
        <li>Cement</li>
        <li>Energy generation</li>
        <li>Water treatment</li>
        <li>Paper pulp industry</li>
      </ul>
      <h1 className="section-header">Product description</h1>
      <p>
        Unidirectional flanged knife gate valve (bidirectional option to order),
        designed for high pressure applications, with self‐cleaning seal.Single
        piece die‐cast body with screwed‐down bonnet and wedges to guarantee the
        seal. Provides high flow rates with low pressure drop. Various seal and
        packing materials available.
      </p>
      <p>It has an arrow on the body indicating the flow direction.</p>
      <h1 className="section-header">Utilization</h1>
      <p>
        The gate valve is suitable for working with clean liquids or liquids
        with a concentration of solids: Drying plants, Paper industry, Water
        Treatment, Chemical Plants Food sector, Mining, Oil extraction, Sludge.
      </p>
      <h1 className="section-header">Sizes</h1>
      <ul className="check-list">
        <li>DN50 a DN1200</li>
        <li>Larger sizes to order.</li>
      </ul>
      <h1 className="section-header">Working pressures</h1>
      <ul className="check-list">
        <li>PN 2,5 to PN 100</li>
        <li>Each valve is designed according to work conditions</li>
      </ul>
    </>
  );
};

export const KnifeGateValveGHContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Knife Gate Valve –  Type GH" }}
        image={knifeGateValveGH}
      />
      <h1 className="section-header">Industrial applications</h1>
      <ul className="check-list">
        <li>Mining Slurry Valves</li>
        <li>Chemical industry</li>
        <li>Cement</li>
        <li>Energy generation</li>
      </ul>
      <h1 className="section-header">Product description</h1>
      <strong>Heavy Duty Slurry Knife Gate Valve</strong>
      <p>
        Bidirectional wafer‐design knife gate valve. “Monoblock” one‐piece cast
        iron body. Stainless steel gate. Two rubber sleeves.Provides high flow
        rates with low pressure drop.
      </p>
      <h1 className="section-header">Utilization</h1>
      <p>
        This knife gate valve is suitable for working in the mining industry, in
        loaded fluid transport lines, such as: water with stones, sludge, etc.
        and in general it is used for abrasive fluids in the chemical industry
        and waster water.
      </p>
      <ul className="check-list">
        <p>Designed for the following applications:</p>
        <li>Mining</li>
        <li>Sewage treatment</li>
        <li>Electrical power stations</li>
        <li>Chemical plants</li>
        <li>Energy Sector</li>
        <li>Thermal power stations</li>
      </ul>
      <h1 className="section-header">Sizes</h1>
      <ul className="check-list">
        <li>DND80‐3″ to ND900‐36″</li>
        <li>Larger sizes on request</li>
      </ul>
      <h1 className="section-header">Working pressures</h1>
      <ul className="check-list">
        <li>ASA 150 DN80-DN600 = 21 bar</li>
        <li>PN 25 DN80-DN600 = 25 bar</li>
        <li>ASA 300/PN40 DN80-DN600 = 40 bar</li>
        <p>
          The pressures indicated in the table can be used in either of the
          valve’s two directions.
        </p>
      </ul>
    </>
  );
};

export const KnifeGateValveGLContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Knife Gate Valve –  Type GL" }}
        image={knifeGateValveGL}
      />
      <h1 className="section-header">Industrial applications</h1>
      <ul className="check-list">
        <li>Mining Slurry Valves</li>
        <li>Chemical industry</li>
        <li>Cement</li>
        <li>Energy generation</li>
      </ul>
      <h1 className="section-header">Product description</h1>
      <strong>Slurry gate valves</strong>
      <p>
        This valve´s main characteristics is that it provides a full continuos
        flow. This means that in open position it produces no cavities and there
        are no turbulences in the fluid.
      </p>
      <ul className="check-list">
        <li>Bidirectional wafer‐design knife gate valve. </li>
        <li>Monoblock one piece cast iron body. </li>
        <li>Stainless steel gate. Two rubber sleeves. </li>
        <li>Provides high flow rates with low pressure drop. </li>
        <li>Various seat materials available. </li>
      </ul>
      <h1 className="section-header">Utilization</h1>
      <p>
        This knife gate slurry valves are suitable for working in the mining
        industry, in loaded fluid transport lines, such as: water with stones,
        sludge, and in general it is used for abrasive fluids in the chemical
        industry and waste water.
      </p>
      <ul className="check-list">
        <p>Designed for the following applications:</p>
        <li>Mining</li>
        <li>Sewage treatment</li>
        <li>Electrical power stations</li>
        <li>Chemical plants</li>
        <li>Energy Sector</li>
        <li>Thermal power stations</li>
      </ul>
      <h1 className="section-header">Sizes</h1>
      <ul className="check-list">
        <li>DN50 to DN1500</li>
        <li>Bigger sizes under request</li>
      </ul>
      <h1 className="section-header">Working pressures</h1>
      <ul className="check-list">
        <li>DN50-DN150 = 16 bar</li>
        <li>DN200-DN600 = 10 bar</li>
        <li>DN700-DN900 = 8 bar</li>
        <li>DN1000-DN1200 = 6 bar</li>
        <p>
          The pressures indicated in the table can be used in either of the
          valve’s two directions.
        </p>
      </ul>
    </>
  );
};

export const KnifeGateValveCContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Knife Gate Valve –  Type C" }}
        image={knifeGateValveC}
      />
      <h1 className="section-header">Industrial applications</h1>
      <ul className="check-list">
        <li>Chemical industry</li>
        <li>Energy generation</li>
        <li>Water treatment</li>
        <li>Paper pulp industry</li>
        <li>Dams and Hydrological Projects</li>
        <li>Food industry</li>
      </ul>
      <h1 className="section-header">Product description</h1>
      <p>
        Square or rectangular shaped unidirectional knife gate valve. Welded
        construction manufacturing. Various seat and packing materials
        available.
      </p>
      <h1 className="section-header">Utilization</h1>
      <p>
        This knife gate valve is suitable for solids, it is also recommended in
        gravity discharge applications for fluids with high solid content.
      </p>
      <p>Mining, Bulk Transport, Chemical plants. Food Industry.</p>
      <ul className="check-list">
        <p>Designed for the following applications:</p>
        <li>Mining</li>
        <li>Sewage treatment</li>
        <li>Electrical power stations</li>
        <li>Chemical plants</li>
        <li>Energy Sector</li>
        <li>Thermal power stations</li>
      </ul>
      <h1 className="section-header">Sizes</h1>
      <ul className="check-list">
        <li>DN50 to DN1500</li>
        <li>Bigger sizes under request</li>
      </ul>
      <h1 className="section-header">Working pressures</h1>
      <ul className="check-list">
        <li>Standard: 0,5 bar</li>
        <li>For larger pressures, consult with MVA Industries</li>
      </ul>
    </>
  );
};
