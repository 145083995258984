import rail from "../../assets/rail-bg.jpg";
import { Content, MainContent } from "../../components/Content/Content";
import DecoratingImage from "../../components/DecoratingImage";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import {
  CeilingsContent,
  DriversCabDoorContent,
  DriversCabWindowContent,
  DriversDeskContent,
  DropdownSlidingWindowContent,
  HingedWindowContent,
  InteriorWallSystemsContent,
  InternalDoorDriversFenceContent,
  InternalDoorsContent,
  InternalWallsContent,
  LocomotivesEquipmentContent,
  MultipleUnitsEquipmentContent,
  PartitionWallsContent,
  PassengerDoorContent,
  SlidingWindowContent,
  TiltWindowContent,
} from "./RailContents";

const options = [
  {
    header: { headerName: "Interior Equipment" },
    content: [
      { name: "Electrical cabinets", link: "/electrical-cabinets" },
      {
        name: "Interior wall systems with doors",
        link: "/interior-wall-systems",
      },
      {
        name: "Internal walls",
        link: "/internal-walls",
      },
      {
        name: "Partition walls",
        link: "/partition-walls",
      },
      { name: "Driver's desk", link: "/drivers-desk" },
      { name: "Ceilings", link: "/ceilings" },
    ],
  },
  {
    header: { headerName: "Doors" },
    content: [
      { name: "Driver's cab door", link: "/drivers-cab-door" },
      { name: "Internal doors", link: "/internal-doors" },
      {
        name: "Passenger door",
        link: "/passenger-door",
      },
      {
        name: "Internal door driver's fence",
        link: "/internal-door-drivers-fence",
      },
    ],
  },
  {
    header: { headerName: "Windows" },
    content: [
      { name: "Tilt window", link: "/tilt-window" },
      { name: "Driver's cab window", link: "/drivers-cab-window" },
      {
        name: "Hinged window",
        link: "/hinged-window",
      },
      { name: "Sliding window", link: "/sliding-window" },
      { name: "Drop-down sliding window", link: "/dropdown-sliding-window" },
    ],
  },
  {
    header: { headerName: "Railway vehicles" },
    content: [
      { name: "Multiple units equipment", link: "/multiple-units" },
      { name: "Locomotives equipment", link: "/locomotives" },
    ],
  },
];

export const RailPage = () => {
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <MainContent heading="Rail" />
      </Content>
      <Footer />
    </>
  );
};

export const InteriorWallSystemsPage = () => {
  options[0].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InteriorWallSystemsContent />
      </Content>
      <Footer />
    </>
  );
};

export const InternalWallsPage = () => {
  options[0].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InternalWallsContent />
      </Content>
      <Footer />
    </>
  );
};

export const PartitionWallsPage = () => {
  options[0].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <PartitionWallsContent />
      </Content>
      <Footer />
    </>
  );
};

export const DriversDeskPage = () => {
  options[0].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DriversDeskContent />
      </Content>
      <Footer />
    </>
  );
};

export const CeilingsPage = () => {
  options[0].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <CeilingsContent />
      </Content>
      <Footer />
    </>
  );
};

export const DriversCabDoorPage = () => {
  options[1].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DriversCabDoorContent />
      </Content>
      <Footer />
    </>
  );
};

export const InternalDoorsPage = () => {
  options[1].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InternalDoorsContent />
      </Content>
      <Footer />
    </>
  );
};

export const PassengerDoorPage = () => {
  options[1].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <PassengerDoorContent />
      </Content>
      <Footer />
    </>
  );
};

export const InternalDoorDriversFencePage = () => {
  options[1].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InternalDoorDriversFenceContent />
      </Content>
      <Footer />
    </>
  );
};

export const TiltWindowPage = () => {
  options[2].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <TiltWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const DriversCabWindowPage = () => {
  options[2].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DriversCabWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const HingedWindowPage = () => {
  options[2].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <HingedWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const SlidingWindowPage = () => {
  options[2].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <SlidingWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const DropdownSlidingWindowPage = () => {
  options[2].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DropdownSlidingWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const MultipleUnitsEquipmentPage = () => {
  options[3].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <MultipleUnitsEquipmentContent />
      </Content>
      <Footer />
    </>
  );
};

export const LocomotivesEquipmentPage = () => {
  options[3].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <LocomotivesEquipmentContent />
      </Content>
      <Footer />
    </>
  );
};
