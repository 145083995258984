import Navbar from "../components/Navbar/Navbar";
import CustomCarousel from "../components/Carousel/Carousel";
import { Tiles } from "../components/Tile/Tile";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
  return (
    <>
      <Navbar activeLink={"home-link"} />
      <div className="container-fluid px-0">
        <CustomCarousel />
      </div>
      <Tiles />
      <Footer />
    </>
  );
};

export default HomePage;
