const DecoratingImage = ({ image }) => {
  return (
    <div className="container-fluid px-0">
      <img
        className="d-block w-100"
        style={{ height: "40vh", objectFit: "cover" }}
        src={image}
        alt={image}
      />
    </div>
  );
};

export default DecoratingImage;
