import globeStopValve from "../../assets/1.1.png";
import controlValve from "../../assets/1.2.png";
import highPressureGlobeValve from "../../assets/1.3.png";
import highPressureControlGlobeValve from "../../assets/1.4.png";
import needleValve from "../../assets/1.5.png";
import globeLiftCheckValves from "../../assets/2.1.png";
import swingCheckValves from "../../assets/2.2.png";
import highPressureGlobeValves from "../../assets/2.3.png";
import waferTypeSwingCheckValve from "../../assets/2.4.png";
import gateValves from "../../assets/3.1.png";
import forgedGateValves from "../../assets/3.2.png";
import highPressureGateValve from "../../assets/3.3.png";
import knifeGateValveA from "../../assets/4.1.png";

export const globeStopValveContent = {
  headerContent: {
    h1Content: "Globe Stop Valve",
    h2Content: ["EN 13709"],
    h3Content: ["DN 15 ÷ DN 350", "PN 16 ÷ PN 160"],
  },
  image: globeStopValve,
  mainContent: {
    Design: [
      "Forged or casted body and bonnet",
      "Bolted bonnet (BB)",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "Seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: ["Pressure up to 160 bar", "Temperature up to 600 °C"],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Electric, hydraulic or pneumatic actuator",
      "Y-Type",
      "Angle type",
      "Position indicator",
      "Extended stem",
      "Inside screw rising stem (IS&RS)",
      "Spring loaded stuffing box (SLSB)",
      "Locking device",
      "Seats and sealings made of elastomeric and polymeric materials",
      "Flanges and welding ends according to : GOST, DIN, ASME, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets",
    ],
  },
};

export const controlValveContent = {
  headerContent: {
    h1Content: "Control Valve",
    h2Content: ["EN 1349 / EN 60534"],
    h3Content: ["DN 15 ÷ DN 350", "PN 16 ÷ PN 160"],
  },
  image: controlValve,
  mainContent: {
    Design: [
      "Forged or casted body and bonnet",
      "Bolted bonnet (BB)",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "Regulating or perforated plug",
      "Seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: ["Pressure up to 160 bar", "Temperature up to 600 °C"],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Electric, hydraulic or pneumatic actuator",
      "Valve characteristic linear or equal-percentage",
      "Multistage design",
      "Y-Type",
      "Angle type",
      "Position indicator",
      "Extended stem",
      "Inside screw rising stem (IS&RS)",
      "Spring loaded stuffing box (SLSB)",
      "Locking device",
      "Seats and sealings made of elastomeric and polymeric materials",
      "Flanges and welding ends according to : GOST, DIN, ASME, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets",
    ],
  },
};

export const highPressureGlobeValveContent = {
  headerContent: {
    h1Content: "High Pressure Globe Valve",
    h3Content: ["DN 10 ÷ DN 200", "PN 250 ÷ PN 500", "Class 1500 ÷ Class 2500"],
  },
  image: highPressureGlobeValve,
  mainContent: {
    Design: [
      "Forged or casted body and bonnet",
      "Pressure seal design",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "Dimensions over DN 50 with balancing disc",
      "Seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: [
      "Pressure up to 500 bar",
      "Class 1500 up to Class 2500",
      "Temperature up to 600 °C",
    ],
    Materials: ["Carbon and heat resistant alloy steels"],
    Options: [
      "Electric, hydraulic or pneumatic actuator",
      "Y-Type",
      "Angle type",
      "Position indicator",
      "Extended stem",
      "Spring loaded stuffing box (SLSB)",
      "Locking device",
      "Tandem Valves",
      "Flanges and welding ends according to : EN, ASME, GOST, DIN, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets",
    ],
  },
};

export const highPressureControlGlobeValveContent = {
  headerContent: {
    h1Content: "High Pressure Control Globe Valve",
    h3Content: ["DN 10 ÷ DN 200", "PN 250 ÷ PN 500", "Class 1500 ÷ Class 2500"],
  },
  image: highPressureControlGlobeValve,
  mainContent: {
    Design: [
      "Forged or casted body and bonnet",
      "Pressure seal design",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "Parabolic or perforated plug",
      "Seats are welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: [
      "Pressure up to 500 bar",
      "Class 1500 up to Class 2500",
      "Temperature up to 600 °C",
    ],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Electric, hydraulic or pneumatic actuator",
      "Y-Type",
      "Angle type",
      "Position indicator",
      "Extended stem",
      "Spring loaded stuffing box (SLSB)",
      "Locking device",
      "Valve characteristic linear or equal-percentage",
      "Flanges and welding ends according to : EN, ASME, GOST, DIN, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets",
    ],
  },
};

export const needleValveContent = {
  headerContent: {
    h1Content: "Needle Valve",
    h3Content: ["DN 6 ÷ DN 15", "PN 160 ÷ PN 630"],
  },
  image: needleValve,
  mainContent: {
    Design: [
      "Forged body",
      "Rising stem",
      "Needle type disc",
      "Seats are integral or welded on ring which is then securely",
      "Screwed into the body",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: ["Pressure up to 630 bar", "Temperature up to 600 °C"],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Seats and sealing made of elastic materials",
      "Socket welding (SW) or Butt welding (BW) ends",
      "Internal or external threaded ends",
      "Other paint finishes are available upon customer’s request",
    ],
  },
};

export const globeLiftCheckValvesContent = {
  headerContent: {
    h1Content: "Globe Lift Check Valves",
    h2Content: ["EN 16767"],
    h3Content: ["DN 15 ÷ DN 350", "PN 16 ÷ PN 160"],
  },
  image: globeLiftCheckValves,
  mainContent: {
    Design: [
      "Forged or casted body and cover",
      "Bolted cover (BC)",
      "Self acting operation",
      "Guided disc pressed with spring on the body seat (Lift type)",
      "Seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: [
      "Pressure up to 160 bar",
      "Class 1500 up to Class 2500",
      "Temperature up to 600 °C",
    ],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Seats and sealing made of elastic materials",
      "Y-Type",
      "Angle type",
      "Flanges and welding ends according to : GOST, DIN, ASME, etc.",
      "Flanges and welding ends according to : EN, ASME, GOST, DIN, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets",
    ],
  },
};

export const swingCheckValvesContent = {
  headerContent: {
    h1Content: "Swing Check Valves",
    h2Content: ["EN 16767"],
    h3Content: ["DN 15 ÷ DN 600", "PN 16 ÷ PN 160"],
  },
  image: swingCheckValves,
  mainContent: {
    Design: [
      "Forged or casted body and cover",
      "Bolted cover (BC)",
      "Anti-blowout design",
      "The disc can rotate on its axis and thereby prevented the local wear",
      "Seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: ["Pressure up to 160 bar", "Temperature up to 600 °C"],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Seats and sealing made of elastic materials",
      "Flanges and welding ends according to : GOST, DIN, ASME, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets Testing",
    ],
  },
};

export const highPressureGlobeValvesContent = {
  headerContent: {
    h1Content: "High Pressure Globe Valves",
    h3Content: ["DN 10 ÷ DN 200", "PN 250 ÷ PN 500", "Class 1500 ÷ Class 2500"],
  },
  image: highPressureGlobeValves,
  mainContent: {
    Design: [
      "Forged or casted body and bonnet",
      "Pressure seal design",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "Dimensions over DN 50 with balancing disc",
      "Seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: [
      "Pressure up to 500 bar",
      "Class 1500 up to Class 2500",
      "Temperature up to 600 °C",
    ],
    Materials: ["Carbon and heat resistant alloy steels"],
    Options: [
      "Electric, hydraulic or pneumatic actuator",
      "Y-Type",
      "Angle type",
      "Position indicator",
      "Extended stem",
      "Spring loaded stuffing box (SLSB)",
      "Locking device",
      "Tandem Valves",
      "Flanges and welding ends according to : EN, ASME, GOST, DIN, etc.",
      "Other paint finishes are available upon customer’s request",
      "Valve complete with counter flanges, bolting and gaskets Testing",
    ],
  },
};

export const waferTypeSwingCheckValveContent = {
  headerContent: {
    h1Content: "Wafer Type Swing Check Valve",
    h2Content: ["EN 16767", "BS 1868", "API 594"],
    h3Content: ["DN 15 ÷ DN 600", "PN 16 ÷ PN 40", "Class 150 ÷ Class 2500"],
  },
  image: waferTypeSwingCheckValve,
  mainContent: {
    Design: [
      "Forged or cast body",
      "The disc can rotate on its axis which prevents local wear",
      "Body seats are integral or welded on",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: [
      "Pressures up to PN 40 (EN 14341)",
      "Class 150 up to Class 2500",
      "Temperature up to 600 °C",
    ],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Options: [
      "Connection ends according to : GOST, DIN, etc.",
      "Other paint finishes are available upon customer’s request",
    ],
  },
};

export const gateValvesContent = {
  headerContent: {
    h1Content: "Gate Valves",
    h2Content: ["EN 1984"],
    h3Content: ["DN 50 ÷ DN 600", "PN 16 ÷ PN 160"],
  },
  image: gateValves,
  mainContent: {
    Design: [
      "Casted body and bonnet",
      "Bolted bonnet (BB)",
      "Rising stem (RS),outside screw and yoke (OS&Y)",
      "Wedge may be one-piece flexible or split wedge type",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: ["Pressure up to 160 bar", "Temperature up to 600 °C"],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Advantages: [
      "Long service life",
      "Respect to emission standards",
      "Easy handling and maintenance",
      "Stem packing replacement in working conditions",
    ],
  },
};

export const forgedGateValvesContent = {
  headerContent: {
    h1Content: "Forged Gate Valves",
    h3Content: ["DN 8 ÷ DN 50", "PN 16 ÷ PN 250"],
  },
  image: forgedGateValves,
  mainContent: {
    Design: [
      "Forged body and bonnet",
      "Bolted bonnet (BB)",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "T-slot design for connection between solid wedge and stem",
      "Body seats pressed into the body",
    ],
    Applications: ["Power plant", "Chemical", "Petrochemical", "Refining"],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: ["Pressures up to 250 bar", "Temperature up to 600 °C"],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Advantages: [
      "Long service life",
      "Respect to emission standards",
      "Easy handling and maintenance",
      "Stem packing replacement in working conditions",
    ],
  },
};

export const highPressureGateValveContent = {
  headerContent: {
    h1Content: "High Pressure Gate Valve",
    h3Content: [
      "DN 15 (1/2”) ÷ DN 300 (12”)",
      "PN 250 ÷ PN 400",
      "Class 900 ÷ Class 2500",
    ],
  },
  image: highPressureGateValve,
  mainContent: {
    Design: [
      "Closed-Die-Forged, welded construction or cast body",
      "Pressure seal design",
      "Rising stem (RS), outside screw and yoke (OS&Y)",
      "T-slot design for connection between solid wedge and stem",
      "Split wedge type obturator",
      "Hard faced seats",
    ],
    Applications: [
      "Power plant",
      "Chemical",
      "Petrochemical",
      "Refining",
      "Water supply and other media",
    ],
    Media: [
      "Depending on the valve materials:",
      "water",
      "steam",
      "oil and oil derivates",
      "other non aggressive media",
    ],
    Pressure: [
      "Class 600 ÷ Class 2500",
      "Pressures up to 400 bar",
      "Temperatures up to 600 °C",
      "p/t according to EN 12156-1 and ASME B16.34 (Appendix A)",
    ],
    Materials: ["Carbon, heat resistant alloy and stainless steels"],
    Advantages: [
      "Long service life",
      "Respect to emission standards",
      "Easy handling and maintenance",
      "Stem packing replacement in working conditions",
    ],
  },
};

export const knifeGateValveAContent = {
  headerContent: {
    h1Content: "Knife Gate Valve –  Type A",
  },
  image: knifeGateValveA,
  mainContent: {
    IndustrialApplications: [
      "Water treatment",
      "Paper pulp industry",
      "Dams and Hydrological Projects",
      "Food industry",
      "Chemical industry",
      "Energy generation",
    ],
    ProductDescription: {
      header: "Unidirectional Knife Gate Valve",
      description:
        "Wafer design valve. One piece cast body with guides to support gate and seat wedges. Provides high flow rates with low pressure drop and include various seat and packing materials available.  An arrow is marked on the body indicating the flow direction. This knife gate valve is suitable for liquids that contain a maximum of 5% suspended solids. If it is used for dry solids in gravity feed applications it should be installed with the arrow on the body pointing in the opposite direction to the flow.",
    },
    Sizes: ["Standard manufacturing sizes: From DN50 up to DN1200"],
    WorkingPressures: [
      "DN 50-250 = 10 bar",
      "DN 300-400 = 6 bar",
      "DN 450 = 5 bar",
      "DN 500-600 = 4 bar",
      "DN 700-1200 = 3 bar",
    ],
  },
};
