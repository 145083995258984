import Navbar from "../components/Navbar/Navbar";
import DecoratingImage from "../components/DecoratingImage";
import Footer from "../components/Footer/Footer";
import valves from "../assets/1_valves Termovents.png";
import Boxes from "../components/Boxes/Boxes";

const ProductsServicesPage = () => {
  return (
    <>
      <Navbar activeLink={"products-link"} />
      <DecoratingImage image={valves} />
      <div
        className="container whole-content services"
        style={{ paddingBottom: "10px" }}
      >
        <Boxes />
      </div>
      <Footer />
    </>
  );
};

export default ProductsServicesPage;
