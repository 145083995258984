import "./content.css";
import { Sidebar } from "../Sidebar/Sidebar";

export const Content = ({ options, children }) => {
  return (
    <div className="container whole-content">
      <div className="row gy-3">
        <div className="col-md-3">
          <Sidebar options={options} />
        </div>
        <div
          className="col-md-9 mainContainer"
          style={{ backgroundColor: "white" }}
        >
          {/* <MainContent /> */}
          {/* <GlobeStopValve /> */}
          {children}
        </div>
      </div>
    </div>
  );
};

export const MainContent = ({ heading }) => {
  return (
    <div style={{ height: "100%" }}>
      <h1>{heading}</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vehicula
        pellentesque faucibus. Class aptent taciti sociosqu ad litora torquent
        per conubia nostra, per inceptos himenaeos. Suspendisse nisi sem, mollis
        ac pulvinar id, egestas sed lectus. Vestibulum ante ipsum primis in
        faucibus orci luctus et ultrices posuere cubilia curae; Nam at urna
        finibus diam pretium pulvinar at sagittis arcu. Aenean et odio nec sem
        tristique ullamcorper et quis enim. Interdum et malesuada fames ac ante
        ipsum primis in faucibus. Vestibulum eget mi in libero commodo cursus at
        sit amet orci. Morbi vitae vestibulum metus, sed auctor dui. Proin sed
        orci vel metus vestibulum dapibus. Ut pharetra lectus ut diam tempor
        consectetur. Duis eu luctus felis. Aenean laoreet mattis nunc finibus
        faucibus. Sed nec congue eros. Pellentesque ullamcorper nisl feugiat
        nunc interdum consectetur.
      </p>
      <p>
        Cras luctus, dui at tristique feugiat, risus dolor consequat dolor,
        porttitor bibendum dolor augue in nulla. Curabitur vel eros ligula.
        Fusce nec condimentum nunc, in dictum dui. Pellentesque varius quis
        ligula quis bibendum. Nullam dolor ante, fermentum ut pulvinar vitae,
        vulputate in ligula. Vestibulum commodo dapibus mauris ac scelerisque.
      </p>
      <p>
        Vivamus sollicitudin libero eget sem placerat, id porta nisi cursus.
        Mauris ipsum metus, pulvinar ac sodales eu, scelerisque ac nulla. Sed
        gravida, eros nec rutrum gravida, eros elit pellentesque magna, dapibus
        fringilla quam diam quis tellus. In hac habitasse platea dictumst. Morbi
        quis quam vel libero volutpat posuere elementum sed ipsum. Quisque
        accumsan mauris vel neque volutpat rhoncus. Nam et odio eleifend,
        vestibulum nunc eu, congue lorem. Vestibulum lacinia nulla sed interdum
        euismod.
      </p>
    </div>
  );
};
