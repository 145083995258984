import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import valves from "../assets/1_valves Termovents.png";
import DecoratingImage from "../components/DecoratingImage";
import Company from "../components/Company/Company";

const CompanyPage = () => {
  return (
    <>
      <Navbar activeLink={"company-link"} />
      <DecoratingImage image={valves} />
      <div
        className="container whole-content company contact"
        style={{ paddingBottom: "10px" }}
      >
        <Company />
      </div>
      <Footer />
    </>
  );
};

export default CompanyPage;
