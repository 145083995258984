import Navbar from "../../components/Navbar/Navbar";
import valves from "../../assets/1_valves Termovents.png";
import Footer from "../../components/Footer/Footer";
import { Content, MainContent } from "../../components/Content/Content";
import DecoratingImage from "../../components/DecoratingImage";
import {
  KnifeGateValveABContent,
  KnifeGateValveCContent,
  KnifeGateValveDContent,
  KnifeGateValveGHContent,
  KnifeGateValveGLContent,
  ValvesContents,
} from "./ValvesContents";
import {
  controlValveContent,
  forgedGateValvesContent,
  gateValvesContent,
  globeLiftCheckValvesContent,
  globeStopValveContent,
  highPressureControlGlobeValveContent,
  highPressureGateValveContent,
  highPressureGlobeValveContent,
  highPressureGlobeValvesContent,
  knifeGateValveAContent,
  needleValveContent,
  swingCheckValvesContent,
  waferTypeSwingCheckValveContent,
} from "./ValvesContentsData";

const options = [
  {
    header: { headerName: "Valves" },
    content: [
      { name: "Globe Stop Valve", link: "/globe-stop-valve" },
      { name: "Control Valve", link: "/control-valve" },
      {
        name: "High Pressure Globe Valve",
        link: "/high-pressure-globe-valve",
      },
      {
        name: "High Pressure Control Globe Valve",
        link: "high-pressure-control-globe-valve",
      },
      { name: "Needle Valve", link: "/needle-valve" },
    ],
  },
  {
    header: { headerName: "Check Valves" },
    content: [
      { name: "Globe Lift Check Valves", link: "/globe-lift-check-valves" },
      { name: "Swing Check Valves", link: "/swing-check-valves" },
      {
        name: "High Pressure Globe Valves",
        link: "/high-pressure-globe-valves",
      },
      {
        name: "Wafer Type Swing Check Valve",
        link: "/wafer-type-swing-check-valve",
      },
    ],
  },
  {
    header: { headerName: "Gate Valves" },
    content: [
      { name: "Gate Valves", link: "/gate-valves" },
      { name: "Forged Gate Valves", link: "/forged-gate-valves" },
      {
        name: "High Pressure Gate Valve",
        link: "/high-pressure-gate-valve",
      },
    ],
  },
  {
    header: { headerName: "Knife Gate Valves" },
    content: [
      { name: "Knife Gate Valve –  Type A", link: "/knife-gate-valve-a" },
      { name: "Knife Gate Valve –  Type AB", link: "/knife-gate-valve-ab" },
      { name: "Knife Gate Valve – Type D", link: "/knife-gate-valve-d" },
      { name: "Knife Gate Valve – Type GH", link: "/knife-gate-valve-gh" },
      { name: "Knife Gate Valve – Type GL", link: "/knife-gate-valve-gl" },
      { name: "Knife Gate Valve – Type C", link: "/knife-gate-valve-c" },
    ],
  },
  {
    header: { headerName: "Butterfly Valves" },
    content: [
      { name: "option1", link: "#" },
      { name: "option2", link: "#" },
    ],
  },
  {
    header: { headerName: "Electric Actuators" },
    content: [
      { name: "option1", link: "#" },
      { name: "option2", link: "#" },
    ],
  },
  {
    header: { headerName: "Pneumatic Actuators" },
    content: [
      { name: "option1", link: "#" },
      { name: "option2", link: "#" },
    ],
  },
];

export const ValvesPage = () => {
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <MainContent heading="Valves & Automation" />
      </Content>
      <Footer />
    </>
  );
};

export const GlobeStopValvePage = () => {
  options[0].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={globeStopValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const ControlValvePage = () => {
  options[0].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={controlValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureGlobeValvePage = () => {
  options[0].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureGlobeValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureControlGlobeValvePage = () => {
  options[0].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureControlGlobeValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const NeedleValvePage = () => {
  options[0].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={needleValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const GlobeLiftCheckValvesPage = () => {
  options[1].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={globeLiftCheckValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const SwingCheckValvesPage = () => {
  options[1].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={swingCheckValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureGlobeValvesPage = () => {
  options[1].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureGlobeValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const WaferTypeSwingCheckValvePage = () => {
  options[1].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={waferTypeSwingCheckValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const GateValvesPage = () => {
  options[2].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={gateValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const ForgedGateValvesPage = () => {
  options[2].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={forgedGateValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureGateValvePage = () => {
  options[2].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureGateValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveAPage = () => {
  options[3].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={knifeGateValveAContent} />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveABPage = () => {
  options[3].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveABContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveDPage = () => {
  options[3].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveDContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveGHPage = () => {
  options[3].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveGHContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveGLPage = () => {
  options[3].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveGLContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveCPage = () => {
  options[3].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveCContent />
      </Content>
      <Footer />
    </>
  );
};
