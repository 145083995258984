import marine from "../../assets/marine-bg.jpg";
import { Content, MainContent } from "../../components/Content/Content";
import DecoratingImage from "../../components/DecoratingImage";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import {
  ArmexContents,
  BohflamContents,
  FireRatedContents,
  HeatingContents,
  InwardOpeningIBContents,
  InwardOpeningIWContents,
  LightweightContents,
  NonOpeningNBContents,
  NonOpeningNOBContents,
  NonOpeningNOWContents,
  NonOpeningNWContents,
  NonTypicalContents,
  OpeningLRBContents,
  OpeningLRWContents,
  OutwardOpeningOBContents,
  OutwardOpeningOWContents,
  PlainGlassContents,
  PrivaControlContents,
  RadarProtectContents,
  SlidingSlihSlivContents,
  SoundControlContents,
  SpraytightContents,
  WatertightContents,
  WeathertightContents,
  YachtContents,
} from "./MarineContents";

const options = [
  {
    header: { headerName: "Marine windows" },
    content: [
      { name: "Non opening - type NOW", link: "/non-opening-now" },
      {
        name: "Non opening - type NOB",
        link: "/non-opening-nob",
      },
      {
        name: "Inward opening - type IW",
        link: "/inward-opening-iw",
      },
      {
        name: "Inward opening - type IB",
        link: "/inward-opening-ib",
      },
      { name: "Outward opening - type OW", link: "/outward-opening-ow" },
      { name: "Outward opening - type OB", link: "/outward-opening-ob" },
      { name: "Sliding - type SLIH/SLIV", link: "/sliding-slih-sliv" },
    ],
  },
  {
    header: { headerName: "Portholes" },
    content: [
      { name: "Opening - type L/RB", link: "/opening-l-rb" },
      { name: "Opening - type L/RW", link: "/opening-l-rw" },
      { name: "Non opening - type NB", link: "/non-opening-nb" },
      { name: "Non opening - type NW", link: "/non-opening-nw" },
    ],
  },
  {
    header: { headerName: "Fireproof products" },
    content: [
      { name: "Bohflam glass", link: "/bohflam-glass" },
      { name: "Armex glass", link: "/armex-glass" },
      {
        name: "Sound control",
        link: "/sound-control",
      },
      { name: "Heating glass", link: "/heating-glass" },
      { name: "Radar protect", link: "/radar-protect" },
      { name: "Priva control", link: "/priva-control" },
      { name: "Plain glass", link: "/plain-glass" },
    ],
  },
  {
    header: { headerName: "Doors" },
    content: [
      { name: "Spraytight", link: "/spraytight" },
      { name: "Weathertight", link: "/weathertight" },
      { name: "Watertight", link: "/watertight" },
      { name: "Yacht", link: "/yacht" },
      { name: "Lightweight", link: "/lightweight" },
      { name: "Fire rated", link: "/fire-rated" },
      { name: "Non typical", link: "/non-typical" },
    ],
  },
];

export const MarinePage = () => {
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <MainContent heading="Marine" />
      </Content>
      <Footer />
    </>
  );
};

export const NonOpeningNOWPage = () => {
  options[0].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <NonOpeningNOWContents />
      </Content>
      <Footer />
    </>
  );
};

export const NonOpeningNOBPage = () => {
  options[0].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <NonOpeningNOBContents />
      </Content>
      <Footer />
    </>
  );
};

export const InwardOpeningIWPage = () => {
  options[0].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <InwardOpeningIWContents />
      </Content>
      <Footer />
    </>
  );
};

export const InwardOpeningIBPage = () => {
  options[0].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <InwardOpeningIBContents />
      </Content>
      <Footer />
    </>
  );
};

export const OutwardOpeningOWPage = () => {
  options[0].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <OutwardOpeningOWContents />
      </Content>
      <Footer />
    </>
  );
};

export const OutwardOpeningOBPage = () => {
  options[0].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <OutwardOpeningOBContents />
      </Content>
      <Footer />
    </>
  );
};

export const SlidingSlihSlivPage = () => {
  options[0].content[6].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <SlidingSlihSlivContents />
      </Content>
      <Footer />
    </>
  );
};

export const OpeningLRBPage = () => {
  options[1].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <OpeningLRBContents />
      </Content>
      <Footer />
    </>
  );
};

export const OpeningLRWPage = () => {
  options[1].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <OpeningLRWContents />
      </Content>
      <Footer />
    </>
  );
};

export const NonOpeningNBPage = () => {
  options[1].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <NonOpeningNBContents />
      </Content>
      <Footer />
    </>
  );
};

export const NonOpeningNWPage = () => {
  options[1].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <NonOpeningNWContents />
      </Content>
      <Footer />
    </>
  );
};

export const BohflamPage = () => {
  options[2].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <BohflamContents />
      </Content>
      <Footer />
    </>
  );
};

export const ArmexPage = () => {
  options[2].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <ArmexContents />
      </Content>
      <Footer />
    </>
  );
};

export const SoundControlPage = () => {
  options[2].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <SoundControlContents />
      </Content>
      <Footer />
    </>
  );
};

export const HeatingPage = () => {
  options[2].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <HeatingContents />
      </Content>
      <Footer />
    </>
  );
};

export const RadarProtectPage = () => {
  options[2].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <RadarProtectContents />
      </Content>
      <Footer />
    </>
  );
};

export const PrivaControlPage = () => {
  options[2].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <PrivaControlContents />
      </Content>
      <Footer />
    </>
  );
};

export const PlainGlassPage = () => {
  options[2].content[6].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <PlainGlassContents />
      </Content>
      <Footer />
    </>
  );
};

export const SpraytightPage = () => {
  options[3].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <SpraytightContents />
      </Content>
      <Footer />
    </>
  );
};

export const WeathertightPage = () => {
  options[3].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <WeathertightContents />
      </Content>
      <Footer />
    </>
  );
};

export const WatertightPage = () => {
  options[3].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <WatertightContents />
      </Content>
      <Footer />
    </>
  );
};

export const YachtPage = () => {
  options[3].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <YachtContents />
      </Content>
      <Footer />
    </>
  );
};

export const LightweightPage = () => {
  options[3].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <LightweightContents />
      </Content>
      <Footer />
    </>
  );
};

export const FireRatedPage = () => {
  options[3].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <FireRatedContents />
      </Content>
      <Footer />
    </>
  );
};

export const NonTypicalPage = () => {
  options[3].content[6].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={marine} />
      <Content options={options}>
        <NonTypicalContents />
      </Content>
      <Footer />
    </>
  );
};
