import warsaw1 from "../../assets/warsaw1.jpg";
import "./company.css";

const Company = () => {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>MVA Industries</h1>
      <div className="company-wrapper">
        <img src={warsaw1} alt="" className="left-image" />
        <div style={{ textAlign: "justify" }} className="right-content">
          <p>
            Welcome to MVA Industries, your trusted partner in international
            trade. We are a Wholesale Export and Import company specializing in
            connecting businesses around the world with high-quality products at
            competitive prices.
          </p>{" "}
          <p>
            At MVA Industries, we are dedicated to providing innovative
            engineering solutions for our clients. With over 15 years of
            experience, our team of skilled engineers and designers have the
            expertise to tackle even the most complex engineering projects. We
            pride ourselves on our ability to stay up-to-date with the latest
            industry trends and technologies, which enables us to provide
            cutting-edge solutions to our clients.
          </p>
          <p>
            With experience in different fields, our small team has built a
            strong network of suppliers and customers across multiple countries
            and industries. This allows us to offer a wide range of products,
            including consumer goods, industrial products, and raw materials, to
            our clients.
          </p>
          <p>
            At MVA Industries, we understand the importance of reliability and
            customer satisfaction. That&#39;s why we are committed to providing
            exceptional service, from product sourcing and logistics to
            after-sales support.
          </p>
          <p>
            Whether you are a retailer looking to diversify your product
            offerings or a manufacturer seeking new markets for your products,
            we are here to help. Contact us today to learn more about how we can
            support your international trade needs.
          </p>
          <p>MVA Industries Is Your Global Partner in Trade.</p>
        </div>
      </div>
    </>
  );
};

export default Company;
