import "./carousel.css";
import warsaw1 from "../../assets/warsaw1.jpg";
import warsaw2 from "../../assets/warsaw2.jpg";
import warsaw3 from "../../assets/warsaw3.jpg";
import warsaw4 from "../../assets/warsaw4.jpg";
import warsaw5 from "../../assets/warsaw5.jpg";
import Carousel from "react-bootstrap/Carousel";

const CustomCarousel = () => {
  return (
    <Carousel fade controls={false} indicators={true}>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100" src={warsaw1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100" src={warsaw2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100" src={warsaw3} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100" src={warsaw4} alt="Fourth slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100" src={warsaw5} alt="Fifth slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default CustomCarousel;
