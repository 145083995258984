import { createBrowserRouter } from "react-router-dom";
import ContactPage from "../pages/ContactPage";
import HomePage from "../pages/HomePage";
import ProductsServicesPage from "../pages/ProductsServicesPage";
import {
  ControlValvePage,
  ForgedGateValvesPage,
  GateValvesPage,
  GlobeLiftCheckValvesPage,
  GlobeStopValvePage,
  HighPressureControlGlobeValvePage,
  HighPressureGateValvePage,
  HighPressureGlobeValvePage,
  HighPressureGlobeValvesPage,
  KnifeGateValveABPage,
  KnifeGateValveAPage,
  NeedleValvePage,
  SwingCheckValvesPage,
  ValvesPage,
  WaferTypeSwingCheckValvePage,
  KnifeGateValveDPage,
  KnifeGateValveGHPage,
  KnifeGateValveGLPage,
  KnifeGateValveCPage,
} from "../pages/ValvesAutomation/ValvesPages";
import CompanyPage from "../pages/CompanyPage";
import {
  CeilingsPage,
  DriversCabDoorPage,
  DriversCabWindowPage,
  DriversDeskPage,
  DropdownSlidingWindowPage,
  HingedWindowPage,
  InteriorWallSystemsPage,
  InternalDoorDriversFencePage,
  InternalDoorsPage,
  InternalWallsPage,
  LocomotivesEquipmentPage,
  MultipleUnitsEquipmentPage,
  PartitionWallsPage,
  PassengerDoorPage,
  RailPage,
  SlidingWindowPage,
  TiltWindowPage,
} from "../pages/Rail/RailPages";
import {
  ArmexPage,
  BohflamPage,
  FireRatedPage,
  HeatingPage,
  InwardOpeningIBPage,
  InwardOpeningIWPage,
  LightweightPage,
  MarinePage,
  NonOpeningNBPage,
  NonOpeningNOBPage,
  NonOpeningNOWPage,
  NonOpeningNWPage,
  NonTypicalPage,
  OpeningLRBPage,
  OpeningLRWPage,
  OutwardOpeningOBPage,
  OutwardOpeningOWPage,
  PlainGlassPage,
  PrivaControlPage,
  RadarProtectPage,
  SlidingSlihSlivPage,
  SoundControlPage,
  SpraytightPage,
  WatertightPage,
  WeathertightPage,
  YachtPage,
} from "../pages/Marine/MarinePages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/products-and-services",
    element: <ProductsServicesPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/company",
    element: <CompanyPage />,
  },
  {
    path: "/valves-and-automation",
    element: <ValvesPage />,
  },
  {
    path: "/globe-stop-valve",
    element: <GlobeStopValvePage />,
  },
  {
    path: "/control-valve",
    element: <ControlValvePage />,
  },
  {
    path: "/high-pressure-globe-valve",
    element: <HighPressureGlobeValvePage />,
  },
  {
    path: "/high-pressure-control-globe-valve",
    element: <HighPressureControlGlobeValvePage />,
  },
  {
    path: "/needle-valve",
    element: <NeedleValvePage />,
  },
  {
    path: "/globe-lift-check-valves",
    element: <GlobeLiftCheckValvesPage />,
  },
  {
    path: "/swing-check-valves",
    element: <SwingCheckValvesPage />,
  },
  {
    path: "/high-pressure-globe-valves",
    element: <HighPressureGlobeValvesPage />,
  },
  {
    path: "/wafer-type-swing-check-valve",
    element: <WaferTypeSwingCheckValvePage />,
  },
  {
    path: "/gate-valves",
    element: <GateValvesPage />,
  },
  {
    path: "/forged-gate-valves",
    element: <ForgedGateValvesPage />,
  },
  {
    path: "/high-pressure-gate-valve",
    element: <HighPressureGateValvePage />,
  },
  {
    path: "/knife-gate-valve-a",
    element: <KnifeGateValveAPage />,
  },
  {
    path: "/knife-gate-valve-ab",
    element: <KnifeGateValveABPage />,
  },
  {
    path: "/knife-gate-valve-d",
    element: <KnifeGateValveDPage />,
  },
  {
    path: "/knife-gate-valve-gh",
    element: <KnifeGateValveGHPage />,
  },
  {
    path: "/knife-gate-valve-gl",
    element: <KnifeGateValveGLPage />,
  },
  {
    path: "/knife-gate-valve-c",
    element: <KnifeGateValveCPage />,
  },
  {
    path: "/rail",
    element: <RailPage />,
  },
  {
    path: "/interior-wall-systems",
    element: <InteriorWallSystemsPage />,
  },
  {
    path: "/internal-walls",
    element: <InternalWallsPage />,
  },
  {
    path: "/partition-walls",
    element: <PartitionWallsPage />,
  },
  {
    path: "/drivers-desk",
    element: <DriversDeskPage />,
  },
  {
    path: "/ceilings",
    element: <CeilingsPage />,
  },
  {
    path: "/drivers-cab-door",
    element: <DriversCabDoorPage />,
  },
  {
    path: "/internal-doors",
    element: <InternalDoorsPage />,
  },
  {
    path: "/passenger-door",
    element: <PassengerDoorPage />,
  },
  {
    path: "/internal-door-drivers-fence",
    element: <InternalDoorDriversFencePage />,
  },
  {
    path: "/tilt-window",
    element: <TiltWindowPage />,
  },
  {
    path: "/drivers-cab-window",
    element: <DriversCabWindowPage />,
  },
  {
    path: "/hinged-window",
    element: <HingedWindowPage />,
  },
  {
    path: "/sliding-window",
    element: <SlidingWindowPage />,
  },
  {
    path: "/dropdown-sliding-window",
    element: <DropdownSlidingWindowPage />,
  },
  {
    path: "/multiple-units",
    element: <MultipleUnitsEquipmentPage />,
  },
  {
    path: "/locomotives",
    element: <LocomotivesEquipmentPage />,
  },
  {
    path: "/marine",
    element: <MarinePage />,
  },
  {
    path: "/non-opening-now",
    element: <NonOpeningNOWPage />,
  },
  {
    path: "/non-opening-nob",
    element: <NonOpeningNOBPage />,
  },
  {
    path: "/inward-opening-iw",
    element: <InwardOpeningIWPage />,
  },
  {
    path: "/inward-opening-ib",
    element: <InwardOpeningIBPage />,
  },
  {
    path: "/outward-opening-ow",
    element: <OutwardOpeningOWPage />,
  },
  {
    path: "/outward-opening-ob",
    element: <OutwardOpeningOBPage />,
  },
  {
    path: "/sliding-slih-sliv",
    element: <SlidingSlihSlivPage />,
  },
  {
    path: "/opening-l-rb",
    element: <OpeningLRBPage />,
  },
  {
    path: "/opening-l-rw",
    element: <OpeningLRWPage />,
  },
  {
    path: "/non-opening-nb",
    element: <NonOpeningNBPage />,
  },
  {
    path: "/non-opening-nw",
    element: <NonOpeningNWPage />,
  },
  {
    path: "/bohflam-glass",
    element: <BohflamPage />,
  },
  {
    path: "/armex-glass",
    element: <ArmexPage />,
  },
  {
    path: "/sound-control",
    element: <SoundControlPage />,
  },
  {
    path: "heating-glass",
    element: <HeatingPage />,
  },
  {
    path: "radar-protect",
    element: <RadarProtectPage />,
  },
  {
    path: "priva-control",
    element: <PrivaControlPage />,
  },
  {
    path: "plain-glass",
    element: <PlainGlassPage />,
  },
  {
    path: "spraytight",
    element: <SpraytightPage />,
  },
  {
    path: "weathertight",
    element: <WeathertightPage />,
  },
  {
    path: "watertight",
    element: <WatertightPage />,
  },
  {
    path: "yacht",
    element: <YachtPage />,
  },
  {
    path: "lightweight",
    element: <LightweightPage />,
  },
  {
    path: "fire-rated",
    element: <FireRatedPage />,
  },
  {
    path: "non-typical",
    element: <NonTypicalPage />,
  },
]);

export default router;
